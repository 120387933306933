import { Api } from './Api';
import Endpoints from './endpoinst';

type Login = {
  email: string;
  senha: string;
};

// LOGIN
export async function AuthLogin(dadosLogin: Login) {
  const resultLogin = await Api.post(Endpoints.auth, dadosLogin);

  return resultLogin.data;
}

type dadosUsuario = {
  nome: string;
  email: string;
  senha: string;
  permissoes: string[];
};

// Admin
export async function postCadastrarUsuario(dadosUsuario: dadosUsuario) {
  const result = await Api.post(Endpoints.usuario, dadosUsuario);

  return result;
}

export async function postCadastrarUnidade(dadosUnidade: any) {
  const result = await Api.post(Endpoints.unidade, dadosUnidade);

  return result;
}

// PLANEJAMENTO
export async function postCadastrarPlanoAplicacao(dadosplano: any) {
  const result = await Api.post(Endpoints.planoAplicacao, dadosplano);

  return result;
}

export async function postCadastrarMetaGeral(dadosMetas: any) {
  const result = await Api.post(Endpoints.metaGeral, dadosMetas);

  return result;
}

export async function postCadastrarMetasFisicas(dadosMetas: any) {
  const result = await Api.post(Endpoints.metaFisica, dadosMetas);

  return result;
}

export async function postCadastrarRemanejamento(dadosMetas: any) {
  const result = await Api.post(Endpoints.remanejamento, dadosMetas);

  return result;
}

// Tipo de Despesa
export async function postCadastrarTipoDespesa(dadosTipoDespesa: any) {
  const result = await Api.post(Endpoints.tipoDespesa, dadosTipoDespesa);

  return result;
}
