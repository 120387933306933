import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Alert, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { schemaTipoDespesa } from './type';
import FormsProvider from '../../../../components/Forms/Provider';
import { FormularioTipoDespesa } from '../Formulario';
import { postCadastrarTipoDespesa } from '../../../../services/post';
import { ToastFun } from '../../../../config/functions';
import ModalCarregamento from '../../../../components/Modal/Carregamento';

export function Cadastro({ open, handleClose }: { open: boolean; handleClose: any }) {
  const methods = useForm({
    resolver: yupResolver(schemaTipoDespesa),
  });

  const { mutate, isPending } = useMutation({
    mutationFn: postCadastrarTipoDespesa,
    onSuccess: () => {
      ToastFun('Tipo de despesa cadastrado com sucesso!', 'success');
    },
  });

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  const onSubmit = async (data: any) => {
    mutate(data);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Adicionar Tipo de Despesa
            </Alert>

            <Box sx={{ my: 1 }}>
              <FormsProvider methods={methods} handleSubmit={onSubmit}>
                <FormularioTipoDespesa />
              </FormsProvider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
