import { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  IconButton,
  TextField,
  Grow,
  Modal,
  Typography,
  MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { getEixosFinanciados, getExericioOrcamentario, getMetasFisicas } from 'src/services/get';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { tratandoValorCentavos } from 'src/config/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { ToastFun } from 'src/config/functions';
import CheckIcon from '@mui/icons-material/Check';
import { ErroResponse } from 'src/pages/Admin/Usuarios/typeUsuario';
import { ExcluirMetaFisica } from 'src/services/delete';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import ModalExcluir from 'src/components/Modal/Excluir';
import CadastroMetaGeral from './Cadastro/MetaGeral';
import MetaFisicaVisualizar from './Visualizar/MetaFisica';
import MetaFisicaFinanceiro from './Financeiro/MetaFisica';

export default function MetaFisica() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [planoAplicacaoId, setPlanoAplicacaoId] = useState<string>('');
  const [eixoFinanciadoId, setEixoFinanciadoId] = useState<string>('');

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };

  const {
    data: rows,
    refetch: RefecthMetas,
    isPending,
  } = useQuery({
    queryKey: ['getMetaFisica', skip, take, search, planoAplicacaoId, eixoFinanciadoId],
    queryFn: getMetasFisicas,
  });

  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { mutate } = useMutation({
    mutationFn: ExcluirMetaFisica,
    onSuccess: (data) => {
      ToastFun('Excluido com Sucesso', 'success');
      RefecthMetas();
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'META FÍSICA',
      flex: 4,
      minWidth: 250,
    },
    {
      field: 'natureza',
      headerName: 'NATUREZA',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => params.row.natureza.toUpperCase(),
    },
    {
      field: 'valorInicial',
      headerName: 'VALOR INICIAL',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          R${' '}
          {tratandoValorCentavos(
            params.row.metasInstituicoes?.reduce(
              (acc: any, item: { valorInicial: any }) => acc + item.valorInicial,
              0
            )
          )}
        </>
      ),
    },
    {
      field: 'Instituições',
      minWidth: 150,
      headerName: 'INSTITUIÇÕES',
      align: 'center',
      flex: 1,

      headerAlign: 'center',
      renderCell: (params) => params.row._count.metasInstituicoes,
    },
    {
      field: 'TipoDespesa',
      minWidth: 150,
      headerName: 'DESPESA',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => params.row.TipoDespesa.nome,
    },

    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,

      renderCell: (params) => (
        <Box>
          {params.row.metaGeral.status === 'Pendente' && (
            <IconButton
              sx={{ color: 'gray' }}
              onClick={() => handleButtonClick(params.row.id, 'Excluir')}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visuzalizar')}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            color="success"
            onClick={() => handleButtonClick(params.row.id, 'Financeiro')}
          >
            <MonetizationOnIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const { data: rowsexercicioOrcamentario } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: getExericioOrcamentario,
  });
  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: getEixosFinanciados,
    enabled: !!planoAplicacaoId,
  });
  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} xl={2} sx={{ my: 2 }}>
          <TextField
            size="small"
            select
            name="exercicioOrcamentario"
            label="Ano"
            fullWidth
            onChange={(event) => setPlanoAplicacaoId(event.target.value)}
          >
            {rowsexercicioOrcamentario &&
              rowsexercicioOrcamentario?.results?.map((item: any, indexExercicio: number) => (
                <MenuItem key={indexExercicio} value={item.id}>
                  {item.exercicioOrcamentario}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={2} xl={4} sx={{ my: 2 }}>
          <TextField
            size="small"
            select
            name="eixoFinanciado"
            label="Eixo Financiado"
            fullWidth
            onChange={(event) => setEixoFinanciadoId(event.target.value)}
          >
            {eixosFinanciados?.results[0].eixoFinanciado.map(
              (item: any, indexEixoFinanciado: number) => (
                <MenuItem key={indexEixoFinanciado} value={item.id}>
                  {item.eixo.nome}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
        <Grid item xs={12} md={5} xl={3} sx={{ my: 2 }}>
          <TextField
            size="small"
            onChange={(event) => setSearch(event.target.value)}
            placeholder="Pesquisa"
            sx={{ mx: 1 }}
          />
          <IconButton color="warning" sx={{ background: '#FADCB2' }} onClick={inputSearch}>
            <SearchIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12} md={3} sx={{ textAlign: 'right', my: 2 }}>
          <Button
            href="/planejamento/fundonacional/metas/cadastro"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Meta Física
          </Button>
        </Grid>
        <Grid xs={12} mt={2}>
          <CustomContainerList title="Metas Físicas">
            {rows && (
              <TableContainer
                columns={columns}
                rows={rows.results}
                rowCount={rows.total}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            )}
          </CustomContainerList>
        </Grid>
      </Grid>
      {isModalOpen && modalType === 'Cadastro' && (
        <CadastroMetaGeral
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefecthMetas={RefecthMetas}
        />
      )}
      {id && isModalOpen && modalType === 'Visuzalizar' && (
        <MetaFisicaVisualizar open={isModalOpen} handleClose={handleCloseModal} id={id} />
      )}
      {id && isModalOpen && modalType === 'Financeiro' && (
        <MetaFisicaFinanceiro open={isModalOpen} handleClose={handleCloseModal} id={id} />
      )}

      {id && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Meta Física"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
