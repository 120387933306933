import { useContext } from 'react';
import { Box, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormsProvider from 'src/components/Forms/Provider';
import { ToastFun } from 'src/config/functions';
// import { postCadastrarMetas } from 'src/services/post';
import { useMutation } from '@tanstack/react-query';
import { AuthContext } from 'src/Context/ContextUser';
import { useNavigate } from 'react-router-dom';
import { ErroResponse } from 'src/pages/Admin/Usuarios/typeUsuario';
import { postCadastrarMetasFisicas } from 'src/services/post';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { schemaMetas } from '../../types';
import MetaGeral from '../../Formulario/MetaGeral';

export default function CadastrarMetasFisicas() {
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(schemaMetas),
  });
  const { state } = useContext(AuthContext);

  const {
    watch,
    setValue,
    formState: { errors },
    unregister,
  } = methods;
  console.log(errors);
  const { mutate, isPending } = useMutation({
    mutationFn: postCadastrarMetasFisicas,
    onSuccess: (data) => {
      navigate('/planejamento/fundonacional/metas/');
      ToastFun('Metas Cadastradas com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  let ValorInvestimento = 0;
  let ValorCusteio = 0;
  const onSubmit = async (data: any) => {
    data.metasFisicas.forEach((item: any) => {
      item.metasInstituicoes?.forEach((instituicao: any) => {
        if (item.natureza === 'custeio') {
          ValorCusteio += instituicao.valorInicial;
        } else if (item.natureza === 'investimento') {
          ValorInvestimento += instituicao.valorInicial;
        }
      });
    });
    data.valorTotalInvestimento = ValorInvestimento;
    data.valorTotalCusteio = ValorCusteio;
    data.metaGeral.usuarioCreate = state.user?.usuario.id;

    console.log(data);

    mutate(data);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={5}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Metas', href: '/planejamento/fundonacional/metas' },
            { name: 'Cadastro', href: '/planejamento/fundonacional/metas/cadastro' },
          ]}
        />
        <CustomContainerCad title="Metas do Plano de Aplicação do Fundo Nacional de Segurança Pública">
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <MetaGeral watch={watch} setValue={setValue} unregister={unregister} />
          </FormsProvider>
        </CustomContainerCad>
      </Stack>
    </Box>
  );
}
