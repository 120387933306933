import { Box, Grid, IconButton, Chip, Modal, Alert, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import { tratandoData, tratandoValorCentavos } from 'src/config/utils';
import { getMetaFisica, getRemanejamento } from 'src/services/get';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function RemanejamentoVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: any;
}) {
  const { data: rowsRemanejamento, isPending } = useQuery({
    queryKey: ['getRemanejamento', id],
    queryFn: getRemanejamento,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  console.log(rowsRemanejamento);
  return (
    <div>
      {rowsRemanejamento && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              p: 5,
              width: {
                xl: '70%',
                md: '85%',
                sm: '70%',
              },
              maxHeight: '650px',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            <Grid container spacing={1}>
              <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid md={12} xs={12}>
                <Alert
                  sx={{ background: '#FFB146', color: 'white', fontWeight: '700' }}
                  icon={<DrawIcon sx={{ color: 'white' }} />}
                >
                  Remanejamento
                </Alert>

                <TableContainer>
                  <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow>
                        <TableCellHead>Execício Orçamentário</TableCellHead>
                        <TableCellHead colSpan={3}>Eixo Financiado</TableCellHead>
                        <TableCellHead align="center">Situação</TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCellBody>
                          {
                            rowsRemanejamento.metaFisicaRecebida.planoAplicacao
                              .exercicioOrcamentario
                          }
                        </TableCellBody>
                        <TableCellBody colSpan={3}>
                          {rowsRemanejamento.metaFisicaRecebida.eixoFinanciado.eixo.nome}
                        </TableCellBody>
                        <TableCellBody align="center">
                          <Chip
                            label={rowsRemanejamento.status}
                            sx={{ color: 'white' }}
                            color={rowsRemanejamento.status === 'Pendente' ? 'warning' : 'success'}
                          />
                        </TableCellBody>
                      </TableRow>

                      <TableRow sx={{ background: '#f5f5f5' }}>
                        <TableCellHead>Meta Física - Retirada</TableCellHead>
                        <TableCellHead colSpan={2}>Meta Física - Recebida</TableCellHead>
                        <TableCellHead>Instituição - Retirada</TableCellHead>
                        <TableCellHead>Instituição - Recebida</TableCellHead>
                      </TableRow>
                      <TableRow>
                        <TableCellBody>{rowsRemanejamento.metaFisicaRetirada.nome}</TableCellBody>
                        <TableCellBody colSpan={2}>
                          {rowsRemanejamento.metaFisicaRecebida.nome}
                        </TableCellBody>
                        <TableCellBody>
                          {rowsRemanejamento.instituicaoRetirada.instituicao.sigla}
                        </TableCellBody>
                        <TableCellBody>
                          {rowsRemanejamento.instituicaoRecebida.instituicao.sigla}
                        </TableCellBody>
                      </TableRow>
                      <TableRow sx={{ background: '#f5f5f5' }}>
                        <TableCellHead>Processo SEI</TableCellHead>
                        <TableCellHead>Valor Remanejado</TableCellHead>
                        <TableCellHead align="center">Natureza</TableCellHead>
                        <TableCellHead align="center">Criado Em </TableCellHead>
                        <TableCellHead align="center">Criado Por </TableCellHead>
                      </TableRow>
                      <TableRow>
                        <TableCellBody>{rowsRemanejamento.processoSei}</TableCellBody>
                        <TableCellBody>
                          R$ {tratandoValorCentavos(rowsRemanejamento.valorRemanejado)}
                        </TableCellBody>
                        <TableCellBody align="center">{rowsRemanejamento.natureza}</TableCellBody>
                        <TableCellBody align="center">
                          {tratandoData(rowsRemanejamento.criadoEm)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          {rowsRemanejamento.usuarioCreate.nome}
                        </TableCellBody>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid md={3}>
                {rowsRemanejamento.status === 'Autorizado' && (
                  <Box my={2}>
                    <Alert severity="info">
                      Autorizado por : {rowsRemanejamento.usuarioAuthorization.nome}
                    </Alert>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  );
}
