import { Theme } from '@mui/material/styles';
import button from 'src/theme/overrides/components/button';
import link from 'src/theme/overrides/components/link';
import typography from 'src/theme/overrides/components/typography';
//

// ----------------------------------------------------------------------

export function componentsOverrides(theme: Theme) {
  const components = {
    ...button(theme),
    ...typography(theme),
    ...link(theme),
  };

  return components;
}
