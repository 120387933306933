import * as yup from 'yup';

export const schemaMetaGeralAtt = yup.object({
  MetaGeral: yup.object().shape({
    nome: yup.string().required('Meta Geral é Obrigatório'),
    planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
    eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
    usuarioUpdateId: yup.string(),
  }),
});
