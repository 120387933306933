import { Container, Typography, Button } from '@mui/material';
import AcessoNegado from 'src/assets/images/AcessoNegado.png';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function AuthPermission() {
  return (
    <Container sx={{ textAlign: 'center' }}>
      <img src={AcessoNegado} alt="" style={{ width: '100%' }} />
      <Typography variant="h3" sx={{ color: '#15803D' }}>
        Acesso Negado
      </Typography>
      <Button
        onClick={() => window.history.back()}
        startIcon={<ArrowBackIcon />}
        variant="contained"
        sx={{ p: 1.5, borderRadius: 5, width: '200px', background: '#019362' }}
      >
        Voltar
      </Button>
    </Container>
  );
}
