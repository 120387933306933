import { Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import PlanoAplicacaoTabelaAutorizacao from './Tabelas/PlanoAplicacao';
import MetasGeraisTabelaAutorizacao from './Tabelas/MetasGerais';
import RemanejamentosAutorizacao from './Tabelas/Remanejamentos';

export default function Autorizacao() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Autorização', href: '/planejamento/fundonacional/autorizacao' },
          ]}
        />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Plano de Aplicação" {...a11yProps(0)} />
              <Tab label="Metas Gerais" {...a11yProps(1)} />
              <Tab label="Remanejamentos" {...a11yProps(2)} />
              {/* <Tab label="Rendimentos" {...a11yProps(3)} /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <PlanoAplicacaoTabelaAutorizacao />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MetasGeraisTabelaAutorizacao />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <RemanejamentosAutorizacao />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={3}>
            oi
          </CustomTabPanel> */}
        </Box>
      </Stack>
    </Box>
  );
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
