import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
//
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { getInstituicoes, getTipoDespesas, getUnidadesInstituicao } from 'src/services/get';
import { useQueries, useQuery } from '@tanstack/react-query';
import { NumericFormat } from 'react-number-format';
import { FieldValues, UseFormWatch, UseFormSetValue, UseFormUnregister } from 'react-hook-form';

interface DadosInstituicao {
  id: string;
  sigla: string;
  nome: string;
  valorInicial: number;
}

interface Metas {
  index: number;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
}

export function MetaFisicaForms({ index, watch, setValue, unregister }: Metas) {
  const [instituicaoBeneficiada, setInstituicaoBeneficiada] = useState<number[]>([0]);

  const novaInstituicao = () => {
    const novasInstituicoes = [...instituicaoBeneficiada];
    const count = novasInstituicoes[novasInstituicoes.length - 1] + 1;
    setInstituicaoBeneficiada([...novasInstituicoes, count]);
  };
  const excluirInstituicao = (indexInst: number) => {
    if (instituicaoBeneficiada.length > 1) {
      const isntID = watch(`metasFisicas[${index}].metasInstituicoes[${indexInst}]`);
      const todasInst = watch(`metasFisicas[${index}].metasInstituicoes`);
      if (isntID) {
        unregister(`metasFisicas[${index}].metasInstituicoes[${indexInst}]`);
        const isntFilter = todasInst.filter((item: any) => item !== null);
        setValue(`metasFisicas[${index}].metasInstituicoes`, isntFilter);
      }
      setInstituicaoBeneficiada((prevInst) => prevInst.filter((inst) => inst !== indexInst));
    }
  };

  const { data: rowsTipoDespesa } = useQuery({
    queryKey: ['getTipoDespesas'],
    queryFn: getTipoDespesas,
  });

  return (
    <Grid container spacing={2} mb={2} p={1}>
      <Grid item xs={12} md={12}>
        <Box px={1} my={1} sx={{ background: '#dde2e9', borderRadius: '15px' }}>
          <Grid container>
            <Grid item md={6} xs={6}>
              <Typography variant="subtitle2">Meta Física</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextFieldRG
          multiline
          type="text"
          name={`metasFisicas[${index}].nome`}
          label="Meta Física"
        />
      </Grid>
      {/* SELECT NATUREZA DESPESA */}
      <Grid item xs={12} md={6}>
        <TextFieldRG select label="Natureza Despesa" name={`metasFisicas[${index}].natureza`}>
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldRG select label="Tipo de Despesa" name={`metasFisicas[${index}].tipoDespesa`}>
          {rowsTipoDespesa?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={novaInstituicao}
        >
          Instituição Beneficiada
        </Button>
      </Grid>
      <Grid item xs={12}>
        {instituicaoBeneficiada.map((item, indexInstituicao) => (
          <InstituiçãoBeneficiada
            key={item}
            indexInstituicao={item}
            index={index}
            instituicaoBeneficiada={instituicaoBeneficiada}
            watch={watch}
            setValue={setValue}
            excluirInstituicao={excluirInstituicao}
          />
        ))}
      </Grid>
    </Grid>
  );
}

function InstituiçãoBeneficiada({
  indexInstituicao,
  index,
  instituicaoBeneficiada,
  watch,
  setValue,
  excluirInstituicao,
}: {
  indexInstituicao: number;
  index: number;
  instituicaoBeneficiada: any;
  watch: any;
  setValue: any;
  excluirInstituicao: any;
}) {
  const valorInical = watch(
    `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].valorInicial`
  );
  const idInstituicao = watch(`metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].id`);
  const instituicaoSelecionadas = watch(`metasFisicas[${index}].metasInstituicoes`);
  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: getInstituicoes,
  });
  const { data: rowsUnidades } = useQuery({
    queryKey: ['getUnidadesInstituicao', idInstituicao],
    queryFn: getUnidadesInstituicao,
    enabled: !!idInstituicao,
  });
  const handleChange = (values: any) => {
    setValue(
      `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].valorInicial`,
      values.value
    );
    setValue(
      `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].saldoEstimativo`,
      values.value
    );
    setValue(
      `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].saldoReal`,
      values.value
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <TextFieldRG
          select
          label="Instituição"
          name={`metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].id`}
        >
          {rowInstituicoes?.results.map((item: any) => (
            <MenuItem
              key={item.id}
              value={item.id}
              disabled={instituicaoSelecionadas?.some((inst: any) => inst.id === item.id)}
            >
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG
          select
          label="Unidade"
          name={`metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].unidade`}
        >
          {rowsUnidades?.unidadesDTO.length > 0 ? (
            rowsUnidades?.unidadesDTO?.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.sigla}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>Nenhuma unidade disponível</MenuItem>
          )}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={4}>
        <NumericFormat
          required
          value={valorInical}
          onValueChange={handleChange}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          prefix="R$ "
          customInput={TextField}
          label="Valor"
          variant="outlined"
          fullWidth
          sx={{ my: 2 }}
        />
      </Grid>
      <Grid item>
        <IconButton
          aria-label="delete"
          disabled={instituicaoBeneficiada.length === 1}
          color="error"
          sx={{ my: 2 }}
          onClick={() => excluirInstituicao(indexInstituicao)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
