import { useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Button,
  IconButton,
  Chip,
  Modal,
  Typography,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Fade,
  Grow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { getMetasGerais } from 'src/services/get';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { ExcluirMetaGeral } from 'src/services/delete';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/Admin/Usuarios/typeUsuario';
import ModalExcluir from 'src/components/Modal/Excluir';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import CadastroMetaGeral from './Cadastro/MetaGeral';
import MetaGeralVisualizar from './Visualizar/MetaGeral';
import AtualizarMetaGeral from './Atualizar/MetaGeral';

export default function MetaGeral() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const {
    data: rows,
    refetch: RefecthMetas,
    isPending,
  } = useQuery({
    queryKey: ['getMetas', skip, take, search],
    queryFn: getMetasGerais,
  });
  console.log(rows);

  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };
  const { mutate: excluirMetaGeral } = useMutation({
    mutationFn: ExcluirMetaGeral,
    onSuccess: (data) => {
      ToastFun('Excluido com Sucesso', 'success');
      RefecthMetas();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  //   if (isPending) {
  //     return <ModalCarregamento isOpen={isPending} />;
  //   }

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'EXERCÍCIO ORÇAMENTÁRIO',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <>{params.row.planoAplicacao.exercicioOrcamentario}</>,
    },
    {
      field: 'nome',
      headerName: 'META GERAL',
      flex: 3,
      minWidth: 250,
    },
    {
      field: '_count',
      headerName: 'METAS FÍSICAS',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>{params.row._count.metaFisica}</>,
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      align: 'center',
      renderCell: (params) => (
        <Box>
          {params.row.status === 'Pendente' && (
            <>
              <IconButton
                color="warning"
                onClick={() => handleButtonClick(params.row.id, 'Editar')}
              >
                <ModeEditIcon />
              </IconButton>
              <IconButton
                sx={{ color: 'gray' }}
                onClick={() => handleButtonClick(params.row.id, 'Excluir')}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visuzalizar')}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} sx={{ my: 2 }}>
          <IconButton color="warning" sx={{ background: '#FADCB2' }} onClick={inputSearch}>
            <SearchIcon />
          </IconButton>
          <Grow
            in={checked}
            style={{ transformOrigin: '10 0 0' }}
            {...(checked ? { timeout: 1000 } : {})}
          >
            <TextField
              sx={{ mx: 1 }}
              size="small"
              onChange={(event) => setSearch(event.target.value)}
            />
          </Grow>
        </Grid>

        <Grid item xs={12} md={3} sx={{ textAlign: 'right', my: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleButtonClick('', 'Cadastro')}
            startIcon={<AddIcon />}
          >
            Meta Geral
          </Button>
        </Grid>
        <Grid xs={12} mt={2}>
          <CustomContainerList title="Metas Gerais" backgroundAlert="#FF8E38">
            {rows && (
              <TableContainer
                columns={columns}
                rows={rows.results}
                rowCount={rows.total}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            )}
          </CustomContainerList>
        </Grid>
      </Grid>
      {isModalOpen && modalType === 'Cadastro' && (
        <CadastroMetaGeral
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefecthMetas={RefecthMetas}
        />
      )}

      {id && isModalOpen && modalType === 'Visuzalizar' && (
        <MetaGeralVisualizar open={isModalOpen} handleClose={handleCloseModal} id={id} />
      )}
      {id && isModalOpen && modalType === 'Editar' && (
        <AtualizarMetaGeral
          open={isModalOpen}
          handleClose={handleCloseModal}
          id={id}
          RefecthMetaGeral={RefecthMetas}
        />
      )}
      {id && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Meta Geral"
          id={id}
          mutate={excluirMetaGeral}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
