import { Box, Modal, Grid, IconButton, Alert, Button, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import {
  getEixosFinanciados,
  getExericioOrcamentario,
  getMetaGeral,
  getPlanoAplicacaoEixosFinanciados,
} from 'src/services/get';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import CachedIcon from '@mui/icons-material/Cached';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { AtualizarMetaGeralID } from 'src/services/patch';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/Admin/Usuarios/typeUsuario';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { schemaMetaGeralAtt } from './type';

export default function AtualizarMetaGeral({
  open,
  handleClose,
  id,
  RefecthMetaGeral,
}: {
  open: boolean;
  handleClose: any;
  id: string;
  RefecthMetaGeral: any;
}) {
  const { state } = useContext(AuthContext);
  const { data: rowsMeta } = useQuery({
    queryKey: ['getMetasGerais', id],
    queryFn: getMetaGeral,
  });
  const methods = useForm({
    resolver: yupResolver(schemaMetaGeralAtt),
  });
  const { watch, reset } = methods;
  useEffect(() => {
    if (rowsMeta) {
      const Metas = {
        MetaGeral: {
          id: rowsMeta.id,
          planoAplicacaoId: rowsMeta.planoAplicacao.id,
          eixoFinanciadoId: rowsMeta.eixoFinanciado.id,
          nome: rowsMeta.nome,
        },
      };
      reset(Metas);
    }
  }, [reset, rowsMeta]);

  const { data: exercicioOrcamentario } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: getExericioOrcamentario,
  });

  const idExercicio = watch('MetaGeral.planoAplicacaoId');

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', idExercicio],
    queryFn: getEixosFinanciados,
    enabled: !!idExercicio,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: AtualizarMetaGeralID,
    onSuccess: (data) => {
      ToastFun('Meta Atualizada com sucesso', 'success');
      handleClose();
      RefecthMetaGeral();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: any) => {
    data.MetaGeral.usuarioUpdate = state.user?.usuario.id;
    data.MetaGeral.atualizadoEm = new Date();
    mutate(data);
  };
  return (
    <>
      {idExercicio && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              p: 5,
              width: {
                xl: '58%',
                md: '75%',
                sm: '70%',
              },
              maxHeight: '95%',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            <Grid container>
              <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid md={12} xs={12}>
                <Alert
                  sx={{ background: '#4B96DD', color: 'white', fontWeight: '700' }}
                  icon={<DrawIcon sx={{ color: 'white' }} />}
                >
                  Meta Geral
                </Alert>
                <Box p={2}>
                  <FormsProvider methods={methods} handleSubmit={onSubmit}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12} md={4}>
                        <TextFieldRG
                          select
                          name="MetaGeral.planoAplicacaoId"
                          label="Exercício Orçamentário"
                        >
                          {exercicioOrcamentario &&
                            exercicioOrcamentario.results.map(
                              (item: any, indexExercicio: number) => (
                                <MenuItem key={indexExercicio} value={item.id}>
                                  {item.exercicioOrcamentario}
                                </MenuItem>
                              )
                            )}
                        </TextFieldRG>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <TextFieldRG
                          select
                          name="MetaGeral.eixoFinanciadoId"
                          label="Eixo/Área Temática"
                        >
                          {eixosFinanciados?.results[0].eixoFinanciado.map(
                            (item: any, indexEixoFinanciado: number) => (
                              <MenuItem key={indexEixoFinanciado} value={item.id}>
                                {item.eixo.nome}
                              </MenuItem>
                            )
                          )}
                        </TextFieldRG>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextFieldRG type="text" name="MetaGeral.nome" label="Meta geral" />
                      </Grid>

                      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
                        <Button
                          variant="contained"
                          color="success"
                          type="submit"
                          startIcon={<CachedIcon />}
                        >
                          Atualizar
                        </Button>
                      </Grid>
                    </Grid>
                  </FormsProvider>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
}
