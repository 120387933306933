import { useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Button,
  IconButton,
  Chip,
  TextField,
  Grow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { SomaTotal, tratandoData, tratandoValorCentavos } from 'src/config/utils';
import { getMetasGerais, getPlanoAplicacao } from 'src/services/get';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SearchIcon from '@mui/icons-material/Search';
import { Navigate, useNavigate } from 'react-router-dom';
import ModalCarregamento from 'src/components/Modal/Carregamento';

export default function MetasGeraisTabelaAutorizacao() {
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('Pendente');
  const [checked, setChecked] = useState<boolean>(false);

  const {
    data: rows,
    refetch: RefecthMeta,
    isPending,
  } = useQuery({
    queryKey: ['getMetasGerais', skip, take, search],
    queryFn: getMetasGerais,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'META GERAL',
      flex: 3,
      minWidth: 250,
    },
    {
      field: '_count',
      headerName: 'METAS FÍSICAS',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>{params.row._count.metaFisica}</>,
    },

    {
      field: 'criadoEm',
      headerName: 'CADASTRADO EM',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'usuarioCreate',
      headerName: 'CADASTRADO POR',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => params.row.metaGeralUsuarioCreate.nome,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton color="primary" onClick={() => navigate(`meta/${params.row.id}`)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Metas Gerais">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
