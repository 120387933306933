export interface User {
  token: string;
  usuario: {
    atualizadoEm: string;
    criadoEm: string;
    email: string;
    id: string;
    nome: string;
  };
  permissoes: string[];
}
// CONTEXT
export interface AuthContextProps {
  state: AuthState; // TRAZENDO DADOS DO USUÁRIO
  loginAuth: (UserData: any) => void;
  logout: () => void;
}

// REDUCER
export interface AuthState {
  isLoading: boolean;
  isAuth: boolean;
  user: User | null;
}
export interface AuthAction {
  type: 'Login' | 'Logout';
  payload?: {
    user: User;
  };
}
export const initialState: AuthState = {
  isAuth: false,
  user: null,
  isLoading: true,
};
