import { Grid, Button, MenuItem, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import {
  getEixosFinanciados,
  getExericioOrcamentario,
  getMetasFisicasRemanejamento,
} from 'src/services/get';
import { tratandoValorCentavos } from 'src/config/utils';
import { NumericFormat } from 'react-number-format';
import { useEffect, useState } from 'react';

export default function FormularioRemanejamento({
  watch,
  setValue,
}: {
  watch: any;
  setValue: any;
}) {
  const [valor, SetValor] = useState();

  const handleChange = (values: any) => {
    console.log(values);
    setValue('valorRemanejado', values.value);
  };

  const { data: exercicioOrcamentario } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: getExericioOrcamentario,
  });

  const idExercicio = watch('planoAplicacaoId');
  const idEixoFinancido = watch('eixoFinanciadoId');
  const natureza = watch('natureza');
  const metaFisicaRetirada = watch('metaFisicaRetiradaId');
  const metaFisicaRecebida = watch('metaFisicaRecebidaId');
  const instituicaoRetirada = watch('instituicaoRetiradaId');

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', idExercicio],
    queryFn: getEixosFinanciados,
    enabled: !!idExercicio,
  });
  const { data: metasFisicas } = useQuery({
    queryKey: ['getmetasFisicasValor', idExercicio, idEixoFinancido, natureza],
    queryFn: getMetasFisicasRemanejamento,
    enabled: !!idExercicio && !!idEixoFinancido && !!natureza,
  });
  console.log(metasFisicas);

  interface Instituicao {
    saldoEstimativo: number;
    Instituicao: [
      {
        id: string;
        sigla: string;
      }
    ];
  }

  function getMetasInstituicoesById(id: string) {
    let result: Instituicao[] = [];
    metasFisicas?.results.forEach((element: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      element.id === id ? (result = element.metasInstituicoes) : [];
    });
    return result || null;
  }

  function saldo(id: string) {
    let result = 0;

    instituicoesRetirada.forEach((element: any) => {
      console.log(element);
      if (element.id === id) {
        result = element.saldoEstimativo;
      }
    });
    return result || null;
  }
  const instituicoesRetirada = getMetasInstituicoesById(metaFisicaRetirada);
  const instituicoesRecebida = getMetasInstituicoesById(metaFisicaRecebida);
  const SaldoinstituicaoRetirada = saldo(instituicaoRetirada) || 0;

  useEffect(() => {
    if (instituicaoRetirada) {
      setValue('valorDisponivel', SaldoinstituicaoRetirada);
    }
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="planoAplicacaoId" label="Exercício Orçamentário">
          {exercicioOrcamentario &&
            exercicioOrcamentario?.results.map((item: any, indexExercicio: number) => (
              <MenuItem key={indexExercicio} value={item.id}>
                {item.exercicioOrcamentario}
              </MenuItem>
            ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="natureza" label="Natureza">
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
          {eixosFinanciados?.results[0].eixoFinanciado.map(
            (item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixo.nome}
              </MenuItem>
            )
          )}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12} lg={8}>
        <TextFieldRG select name="metaFisicaRetiradaId" label="Meta Física - Retirada">
          {metasFisicas?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="instituicaoRetiradaId" label="Instituição - Retirada">
          {instituicoesRetirada.map((item: any) => (
            <MenuItem key={item.instituicao?.id} value={item.id}>
              {item.instituicao?.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <TextFieldRG select name="metaFisicaRecebidaId" label="Meta Física - Recebida">
          {metasFisicas?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="instituicaoRecebidaId" label="Instituição - Recebida">
          {instituicoesRecebida.map((item: any) =>
            metaFisicaRetirada === metaFisicaRecebida ? (
              instituicaoRetirada !== item.id && (
                <MenuItem key={item.instituicao?.id} value={item.id}>
                  {item.instituicao?.sigla}
                </MenuItem>
              )
            ) : (
              <MenuItem key={item.instituicao?.id} value={item.id}>
                {item.instituicao?.sigla}
              </MenuItem>
            )
          )}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG
          label="Valor Disponível"
          value={`R$ ${tratandoValorCentavos(SaldoinstituicaoRetirada)}`}
          disabled
          name="valorDisponivel"
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <NumericFormat
          required
          value={valor}
          onValueChange={handleChange}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          prefix="R$ "
          customInput={TextField}
          label="Valor Remanejado"
          variant="outlined"
          fullWidth
          sx={{ my: 2 }}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRG label="Processo SEI" name="processoSei" />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
