import { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Select,
  MenuItem,
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Alert,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
// import { getEixos, ToastFun } from 'src/config/functions';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery } from '@tanstack/react-query';
import {
  getEixosFinanciados,
  getExericioOrcamentario,
  getMetasFisicasValor,
} from 'src/services/get';
import { tratandoValorCentavos } from 'src/config/utils';
import { FieldValues, UseFormSetValue, UseFormUnregister, UseFormWatch } from 'react-hook-form';
import { MetaFisicaForms } from './MetaFisica';

interface Plano {
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
}
export default function MetaGeral({ watch, setValue, unregister }: Plano) {
  const [metasFisicas, setMetasFisicas] = useState<number[]>([0]);
  const lengthMetas = watch('metasFisicas');
  useEffect(() => {
    if (lengthMetas?.length > 0) {
      const indicesArray = lengthMetas?.map((_: any, index: any) => index);
      setMetasFisicas(indicesArray);
    }
  }, [lengthMetas]);
  const novaMeta = () => {
    const novoEixos = [...metasFisicas];
    const count = novoEixos[novoEixos.length - 1] + 1;
    setMetasFisicas([...novoEixos, count]);
  };

  const { data: exercicioOrcamentario } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: getExericioOrcamentario,
  });

  const planoAplicacaoId = watch('metaGeral.planoAplicacaoId');
  const eixoFinanciadoId = watch('metaGeral.eixoFinanciadoId');

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: getEixosFinanciados,
    enabled: !!planoAplicacaoId,
  });

  const { data: metasFisicasValorEixo } = useQuery({
    queryKey: ['getmetasFisicasValor', planoAplicacaoId, eixoFinanciadoId],
    queryFn: getMetasFisicasValor,
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId,
  });
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="metaGeral.planoAplicacaoId" label="Exercício Orçamentário">
          {exercicioOrcamentario &&
            exercicioOrcamentario.results.map((item: any, indexExercicio: number) => (
              <MenuItem key={indexExercicio} value={item.id}>
                {item.exercicioOrcamentario}
              </MenuItem>
            ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG
          select
          name="metaGeral.eixoFinanciadoId"
          label="Eixo/Área Temática"
          sx={{
            maxWidth: {
              md: 750,
              xs: 300,
              sm: 500,
              lg: 820,
            },
            mt: 2,
          }}
        >
          {eixosFinanciados?.results[0].eixoFinanciado.map(
            (item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixo.nome}
              </MenuItem>
            )
          )}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG
          select
          fullWidth
          name="metaGeral.id"
          label="Meta Geral"
          sx={{
            maxWidth: {
              md: 750,
              xs: 300,
              sm: 500,
              lg: 820,
            },
            mt: 2,
          }}
        >
          {metasFisicasValorEixo?.metaGeral.map((item: any, indexMetaGeral: number) => (
            <MenuItem key={indexMetaGeral} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      {metasFisicasValorEixo && (
        <>
          <Grid item xs={12} sm={12} md={12} sx={{ textAlign: 'center' }}>
            <Alert variant="outlined" severity="warning" sx={{ mx: 1 }}>
              Os valores cadastrados não devem exceder o valor disponível para este eixo!
            </Alert>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: 'center' }}>
            <Card sx={{ background: '#E8F7FF', color: '#4CC0FF' }}>
              <CardContent>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }} gutterBottom>
                  Valor Disponível Investimento
                </Typography>

                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  R$ {tratandoValorCentavos(metasFisicasValorEixo.valorInvestimentoDisponivel)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: 'center' }}>
            <Card sx={{ background: '#ECF2FF', color: '#5D87FF' }}>
              <CardContent>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }} gutterBottom>
                  Valor Disponível Custeio
                </Typography>

                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  R$ {tratandoValorCentavos(metasFisicasValorEixo.valorCusteioDisponivel)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
      {/* METAS FISICAS */}
      <Grid item xs={12}>
        {metasFisicas.map((item, indexMeta) => (
          <MetaFisicaForms
            key={indexMeta}
            index={item}
            watch={watch}
            setValue={setValue}
            unregister={unregister}
          />
        ))}
      </Grid>
      <Grid item xs={12} md={12} mt={3} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={novaMeta}>
          Meta Física
        </Button>
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
