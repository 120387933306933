import { toast } from 'react-toastify';
import { Api } from 'src/services/Api';

//Toast de Mensagens
export function ToastFun(Texto: string, Type: any) {
  return toast(Texto, {
    type: Type,
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
}
