import React, { createContext, useReducer, useEffect, useMemo } from 'react';
import { AuthState, User, initialState, AuthContextProps } from './types';

// APLICANDO REDUCER
const reducer = (state: AuthState, action: any) => {
  switch (action.type) {
    case 'Login':
      return {
        ...state,
        isAuth: true,
        user: action.payload?.user,
        isLoading: false,
      };
    case 'Logout':
      return {
        ...state,
        isAuth: false,
        user: null,
        isLoading: false,
      };

    default:
      return state;
  }
};
export const AuthContext = createContext<AuthContextProps>({
  state: initialState,
  loginAuth: () => {},
  logout: () => {},
});

interface AuthProviderChildren {
  children: JSX.Element;
}

export const AuthProvider = ({ children }: AuthProviderChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const userData = JSON.parse(user);
      dispatch({ type: 'Login', payload: { user: userData } });
    } else {
      dispatch({ type: 'Logout' });
    }
  }, []);

  const loginAuth = (UserData: User) => {
    localStorage.setItem('user', JSON.stringify(UserData));
    dispatch({
      type: 'Login',
      payload: { user: UserData },
    });
  };

  const logout = () => {
    localStorage.removeItem('user');
    dispatch({
      type: 'Logout',
    });
  };
  const contextValue = useMemo(
    () => ({
      state,
      loginAuth,
      logout,
    }),
    [state]
  );
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
