import { Box, Modal, Grid, IconButton, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import { getPlanoAplicacaoEixosFinanciados } from 'src/services/get';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import FormPlanoAplicacao from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Formulario/Plano';
import { yupResolver } from '@hookform/resolvers/yup';
import { tratandoDataInput, tratandoValorCentavosEditar } from 'src/config/utils';
import { AtualizarPlanoEixos } from 'src/services/patch';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/Admin/Usuarios/typeUsuario';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { schemaPlano } from '../type';

export default function AtualizarPlano({
  open,
  handleClose,
  id,
  RefecthPlano,
}: {
  open: boolean;
  handleClose: any;
  id: string;
  RefecthPlano: any;
}) {
  const { state } = useContext(AuthContext);

  // BUSCAR TODOS OS DADOS DO PLANO
  const {
    data: rowsEixosFinanciados,
    refetch: RefecthEixos,
    isPending,
  } = useQuery({
    queryKey: ['getPlanoAplicacaoEixosFinanciados', id],
    queryFn: getPlanoAplicacaoEixosFinanciados,
  });

  // ATRIBUINDO VALORES PADROES - INPUT
  const methods = useForm({
    resolver: yupResolver(schemaPlano),
    defaultValues: rowsEixosFinanciados,
  });

  // SALVAR ATUALIZAÇÃO - RAQUISIÇÃO
  const { mutate } = useMutation({
    mutationFn: AtualizarPlanoEixos,
    onSuccess: (data) => {
      ToastFun('Plano Atualizado com sucesso', 'success');
      RefecthPlano();
      RefecthEixos();
      handleClose();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  // ATIBUINDO VALORES INPUT - TRATANDO VALORES DOS CAMPOS MONETÁRIOS
  useEffect(() => {
    if (rowsEixosFinanciados && !rowsEixosFinanciados.alt) {
      rowsEixosFinanciados.planoAplicacao.prazoExecucao = tratandoDataInput(
        rowsEixosFinanciados.planoAplicacao.prazoExecucao
      );

      rowsEixosFinanciados.eixoFinanciado.forEach((item: any) => {
        item.valorInicialCusteio /= 100;
        item.valorInicialInvestimento /= 100;
      });
      rowsEixosFinanciados.alt = 1;
      methods.reset(rowsEixosFinanciados);
    }
  }, [rowsEixosFinanciados, methods]);

  const {
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = methods;

  const EixosFinanciados = watch('eixoFinanciado');
  const exercicioOrcamentario = watch('planoAplicacao.exercicioOrcamentario');

  // ENVIADO DADOS PARA A
  const onSubmit = async (data: any) => {
    data.planoAplicacao.usuarioUpdateId = state.user?.usuario.id;
    data.planoAplicacao.atualizadoEm = new Date();
    mutate(data);
  };
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  return (
    <>
      {rowsEixosFinanciados && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              p: 5,
              width: {
                xl: '58%',
                md: '75%',
                sm: '70%',
              },
              maxHeight: '95%',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            <Grid container>
              <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid md={12} xs={12}>
                <Alert
                  sx={{ background: '#4B96DD', color: 'white', fontWeight: '700' }}
                  icon={<DrawIcon sx={{ color: 'white' }} />}
                >
                  Plano de Ação - {rowsEixosFinanciados.planoAplicacao.exercicioOrcamentario}
                </Alert>
                <Box p={2}>
                  <FormsProvider methods={methods} handleSubmit={onSubmit}>
                    <FormPlanoAplicacao
                      EixosFinanciados={EixosFinanciados}
                      exercicioOrcamentario={exercicioOrcamentario}
                      watch={watch}
                      setValue={setValue}
                      unregister={unregister}
                      RefecthEixos={RefecthEixos}
                    />
                  </FormsProvider>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
}
