import { Button, InputAdornment } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import HttpsIcon from '@mui/icons-material/Https';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';

export default function LoginForm() {
  return (
    <>
      <TextFieldRG
        sx={{ my: 2 }}
        name="email"
        fullWidth
        placeholder="Email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon sx={{ color: '#c4c4c4' }} />
            </InputAdornment>
          ),
        }}
        label=""
      />
      <TextFieldRG
        sx={{ my: 2 }}
        name="senha"
        fullWidth
        type="password"
        placeholder="Senha"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HttpsIcon sx={{ color: '#c4c4c4' }} />
            </InputAdornment>
          ),
        }}
        label=""
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        startIcon={<LoginIcon />}
        sx={{
          borderRadius: '8px',
          background: 'rgba(255, 191, 0, 1)',
          '&:hover': { background: 'rgb(255, 184, 5)' },
          py: 1.5,
          my: 2,
        }}
      >
        ENTRAR
      </Button>
    </>
  );
}
