import { Box, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import { getEixos } from 'src/services/get';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { tratandoValorCentavosEditar } from 'src/config/utils';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

interface Eixo {
  index: number;
  watch: any;
  setValue: any;
  type?: string;
  excluirEixo: any;
}

export function EixoForms({ index, watch, setValue, type, excluirEixo }: Eixo) {
  const [ValorCusteio, SetValorCusteio] = useState(
    watch(`eixoFinanciado.${index}.valorInicialCusteio`)
  );
  const [ValorInvestimento, SetValorInvestimento] = useState(
    watch(`eixoFinanciado.${index}.valorInicialInvestimento`)
  );

  // BUSCAR EIXOS CADASTRADOS
  const { data: eixosCad } = useQuery({
    queryKey: ['getEixos'],
    queryFn: getEixos,
  });

  const handleChangeCusteio = (values: any) => {
    SetValorCusteio(values.floatValue);
  };
  const handleChangeInvestimento = (values: any) => {
    SetValorInvestimento(values.floatValue);
  };

  useEffect(() => {
    if (ValorCusteio) {
      setValue(`eixoFinanciado.${index}.valorInicialCusteio`, ValorCusteio);
      setValue(`eixoFinanciado.${index}.saldoEstimativoCusteio`, ValorCusteio);
      setValue(`eixoFinanciado.${index}.saldoRealCusteio`, ValorCusteio);
    }
    if (ValorInvestimento) {
      setValue(`eixoFinanciado.${index}.valorInicialInvestimento`, ValorInvestimento);
      setValue(`eixoFinanciado.${index}.saldoEstimativoInvestimento`, ValorInvestimento);
      setValue(`eixoFinanciado.${index}.saldoRealInvestimento`, ValorInvestimento);
    }
  }, [ValorCusteio, ValorInvestimento, index, setValue]);

  return (
    <Grid container spacing={2} mb={5}>
      <Grid item xs={12} md={12}>
        <Box px={1} my={1} sx={{ background: '#dde2e9', borderRadius: '15px' }}>
          <Grid container>
            <Grid item md={6} xs={10}>
              <Typography
                sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
                variant="subtitle2"
              >
                Eixo Associado
              </Typography>
            </Grid>
            <Grid item md={6} xs={2} sx={{ textAlign: 'right' }}>
              <IconButton aria-label="delete" size="small" onClick={() => excluirEixo(index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* SELECT EIXO */}
      <Grid item xs={12} md={12}>
        <TextFieldRG select name={`eixoFinanciado[${index}].eixoId`} label="Eixo">
          {eixosCad &&
            eixosCad.results.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography variant="subtitle2">Investimento</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="text"
          name={`eixoFinanciado[${index}].agenciaInvestimento`}
          label="Agência"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="text"
          name={`eixoFinanciado[${index}].contaInvestimento`}
          label="Conta"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <NumericFormat
          value={ValorInvestimento}
          onValueChange={handleChangeInvestimento}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          prefix="R$ "
          customInput={TextField}
          label="Valor"
          variant="outlined"
          fullWidth
          sx={{ my: 2 }}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography variant="subtitle2">Custeio</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG type="text" name={`eixoFinanciado[${index}].agenciaCusteio`} label="Agência" />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG type="text" name={`eixoFinanciado[${index}].contaCusteio`} label="Conta" />
      </Grid>
      <Grid item xs={12} md={4}>
        <NumericFormat
          value={ValorCusteio}
          onValueChange={handleChangeCusteio}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          prefix="R$ "
          customInput={TextField}
          label="Valor"
          variant="outlined"
          fullWidth
          sx={{ my: 2 }}
        />
      </Grid>
    </Grid>
  );
}
