import { useEffect, useState } from 'react';
import { Grid, Button, Select, MenuItem, Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useMutation, useQuery } from '@tanstack/react-query';
import CachedIcon from '@mui/icons-material/Cached';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/Admin/Usuarios/typeUsuario';
import { ExcluirEixoFinanciado } from 'src/services/delete';
import { EixoForms } from './Eixo';

export default function FormPlanoAplicacao({
  dadosCadastrados,
  unregister,
  EixosFinanciados,
  exercicioOrcamentario,
  watch,
  type,
  setValue,
  RefecthEixos,
}: {
  dadosCadastrados?: object;
  EixosFinanciados?: [];
  exercicioOrcamentario?: string;
  watch: any;
  type?: string;
  setValue: any;
  unregister: any;
  RefecthEixos?: any;
}) {
  const [eixos, setEixos] = useState<number[]>([0]);

  const { mutate: excluirEixoFinanciado } = useMutation({
    mutationFn: ExcluirEixoFinanciado,
    onSuccess: (data) => {
      ToastFun('Excluido com Sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const novoEixo = () => {
    const novoEixos = [...eixos];
    const count = novoEixos[novoEixos.length - 1] + 1;
    setEixos([...novoEixos, count]);
  };
  const excluirEixo = (index: number) => {
    if (eixos.length > 1) {
      const idEixo = watch(`eixoFinanciado.${index}.id`);
      const eixosPlano = watch(`eixoFinanciado`);
      if (idEixo) {
        const dadosExcluir = {
          id: idEixo,
          status: watch(`planoAplicacao.status`),
        };
        excluirEixoFinanciado(dadosExcluir);
        unregister(`eixoFinanciado.${index}`);
        const Eixos = eixosPlano.filter((item: any) => item !== null);
        setValue(`eixoFinanciado`, Eixos);

        setEixos((prevEixos) => prevEixos.filter((eixo) => eixo !== index));
      } else {
        unregister(`eixoFinanciado.${index}`);
        const Eixos = eixosPlano.filter((item: any) => item !== null);
        setValue(`eixoFinanciado`, Eixos);

        setEixos((prevEixos) => prevEixos.filter((eixo) => eixo !== index));
      }
    } else {
      ToastFun('O Plano precisa de um pelo menos um Eixo', 'error');
    }
  };

  useEffect(() => {
    if (dadosCadastrados) {
      setEixos([0]);
    }
    if (EixosFinanciados && EixosFinanciados?.length > 0) {
      const eixoAtualizar: number[] = [];
      EixosFinanciados.map((item, index) => eixoAtualizar.push(index));
      setEixos(eixoAtualizar);
    }
  }, [dadosCadastrados, EixosFinanciados]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <TextFieldRG
            disabled={!dadosCadastrados}
            select
            name="planoAplicacao.exercicioOrcamentario"
            label="Exercício Orçamentário"
          >
            <MenuItem value="2019">2019</MenuItem>
            <MenuItem value="2020">2020</MenuItem>
            <MenuItem value="2021">2021</MenuItem>
            <MenuItem value="2022">2022</MenuItem>
            <MenuItem value="2023">2023</MenuItem>
            <MenuItem value="2024">2024</MenuItem>
          </TextFieldRG>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="date"
          name="planoAplicacao.prazoExecucao"
          label="Prazo de Execução"
          focused
        />
      </Grid>

      <Grid item xs={12} md={4} mt={5} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={novoEixo}>
          Novo Eixo
        </Button>
      </Grid>
      <Grid item xs={12}>
        {eixos.map((item, index) => (
          <EixoForms
            key={item}
            index={item}
            watch={watch}
            setValue={setValue}
            type={type}
            excluirEixo={excluirEixo}
          />
        ))}
      </Grid>
      {!exercicioOrcamentario && (
        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
          <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
            Cadastrar
          </Button>
        </Grid>
      )}
      {exercicioOrcamentario && (
        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
          <Button variant="contained" color="success" type="submit" startIcon={<CachedIcon />}>
            Atualizar
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
