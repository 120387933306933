import { Button, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from '../../../../components/Forms/TextFieldRG';

export function FormularioTipoDespesa() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={8}>
        <TextFieldRG name="nome" label="Nome" />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
