// TYPES / DEFAULT
import * as yup from 'yup';
import { tratandoValor } from '../../../../config/utils';

export interface DataPlano {
  prazoExecucao: string;

  exercicioOrcamentario: string;
  valorInicialCusteio: any;
  valorInicialInvestimento: any;

  usuarioCreateId: string;
  usuarioUpdateId: string;
  usuarioAuthorizationId: string;

  eixoFinanciado: Eixos[];
}

export interface Eixos {
  eixoId: string;
  agenciaInvestimento: string;
  contaInvestimento: string;
  valorInvestimento: any;
  agenciaCusteio: string;
  contaCusteio: string;
  valorCusteio: any;
  planoAplicacaoId: string;
}

export const schemaPlano = yup.object<DataPlano>({
  planoAplicacao: yup.object().shape({
    exercicioOrcamentario: yup.string().required('Ano é obrigatório'),
    prazoExecucao: yup.string().required('Prazo de Execução é obrigatório'),
    valorInicialCusteio: yup.mixed().transform((value: any) => tratandoValor(value)),
    valorInicialInvestimento: yup.mixed().transform((value: any) => tratandoValor(value)),
  }),

  eixoFinanciado: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        eixoId: yup.string().required('Eixo é obrigatório'),
        agenciaInvestimento: yup.string().required('Agência de Investimento é obrigatório'),
        contaInvestimento: yup.string().required('Conta de Investimento é obrigatório'),
        valorInicialInvestimento: yup
          .mixed()
          .required('valor de Investimento é obrigatório')
          .transform((value: any) => tratandoValor(value)),
        agenciaCusteio: yup.string().required('Agência de Custeio é obrigatório'),
        contaCusteio: yup.string().required('Conta de Custeio é obrigatório'),
        valorInicialCusteio: yup
          .mixed()
          .required('valor de Custeio é obrigatório')
          .transform((value: any) => tratandoValor(value)),
        planoAplicacaoId: yup.string(),
      })
    )
    .required(),
});

export const defaultValuesPlano = {
  eixoFinanciado: [],
  planoAplicacao: {
    prazoExecucao: '',
    exercicioOrcamentario: '',
    valorInicialCusteio: 0,
    valorInicialInvestimento: 0,
    usuarioCreateId: null,
    usuarioUpdateId: null,
    usuarioAuthorizationId: null,
  },
};
