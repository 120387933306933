/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { Grid, Typography, Button, TextField } from '@mui/material';
import { funcoesData, modulosData } from 'src/pages/Admin/Usuarios/typeUsuario';
import InputMask from 'react-input-mask';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name: string, modulos: string[], theme: Theme) {
  return {
    fontWeight:
      modulos.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
type Permissao = {
  nome: string;
};
type FormsUsuario = {
  setValue: any;
  modulos: string[];
  funcoes: string[];
  setModulos: any;
  setFuncoes: any;
};

export default function FormularioCadastrarUsuario({
  setValue,
  modulos,
  funcoes,
  setModulos,
  setFuncoes,
}: FormsUsuario) {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof modulos>) => {
    const {
      target: { name, value },
    } = event;
    console.log(name, value);

    if (name === 'funcao') {
      setFuncoes(typeof value === 'string' ? value.split(',') : value);
    } else {
      setModulos(typeof value === 'string' ? value.split(',') : value);
    }
  };

  useEffect(() => {
    if (funcoes.includes('administrador')) {
      setModulos(['admin']);
      setFuncoes(['administrador']);
    } else {
      const modulosFiltrados = modulos.filter((modulo) =>
        funcoes.some((funcao) => modulo.includes(funcao))
      );
      setModulos(modulosFiltrados);
    }
    const novasPermissoes = modulos.map((item: string) => ({ nome: item }));
    setValue('permissoes', novasPermissoes);
  }, [modulos, funcoes]);

  return (
    <Grid container spacing={2} sx={{ color: '#555555' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Email</Typography>
        <TextFieldRG type="text" name="email" label="" />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Nome</Typography>
        <TextFieldRG type="text" name="nome" label="" />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">CPF</Typography>
        <TextFieldRGMaks label="" name="cpf" mask="___.___.___-__" />
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Senha</Typography>
        <TextFieldRG type="password" name="senha" label="" />
      </Grid>
      <Grid item xs={12} md={4} mt={2}>
        <Typography variant="subtitle2">Função</Typography>
        <FormControl fullWidth>
          <Select
            name="funcao"
            multiple
            value={funcoes}
            onChange={handleChange}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            required
          >
            {funcoesData.map((name) => (
              <MenuItem
                disabled={name !== 'ADMINISTRADOR' && funcoes.includes('administrador')}
                key={name}
                value={`${name
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f\s]/g, '')
                  .toLocaleLowerCase()}`}
                style={getStyles(name, funcoes, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {!funcoes.includes('administrador') && (
        <>
          {funcoes.map(
            (item: string, index: number) =>
              item !== 'administrador' && (
                <Grid key={index} item xs={12} md={4} mt={2}>
                  <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                    {item} - Módulos{' '}
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      name="modulos"
                      multiple
                      value={modulos}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                      {modulosData[item] &&
                        modulosData[item].map((name: string) => (
                          <MenuItem
                            key={name}
                            value={`${item}_${name
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f\s]/g, '')
                              .toLocaleLowerCase()}`}
                            style={getStyles(name, modulos, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )
          )}
        </>
      )}
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
