import { Container, Box, Typography, Button, Divider, Grid, IconButton } from '@mui/material';
import LogoMinerva from 'src/assets/images/Minverva_horizontal.png';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useState, useEffect } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { PublicsModules, PivateModules } from './modulos';

interface CustomButtonProps {
  data: {
    title: string;
    link: string;
    icon: JSX.Element;
    permissao: string;
  };
}

function CustomButton({ data }: CustomButtonProps) {
  return (
    <Button
      variant="soft"
      color="success"
      size="large"
      href={data.link}
      fullWidth
      sx={{
        p: 10,
        borderRadius: 5,
        boxShadow: '1px 1px 2px 1px #c4c4c4',
      }}
    >
      <Grid container sx={{ textAlign: 'center' }}>
        <Grid md={12} xs={12}>
          {data.icon}
        </Grid>
        <Grid md={12} xs={12}>
          <Typography variant="subtitle2">{data.title}</Typography>
        </Grid>
      </Grid>
    </Button>
  );
}

interface Modulo {
  title: string;
  link: string;
  icon: JSX.Element;
  permissao: string;
}
type Modulos = Modulo[];

export default function MenuCards() {
  const navigate = useNavigate();
  const { state, logout } = useContext(AuthContext);
  const [modulosPrivados, setModulosPrivados] = useState<Modulos>([]);

  const sair = () => {
    logout();
    navigate('/');
  };
  useEffect(() => {
    if (state.user?.permissoes.includes('*')) {
      setModulosPrivados(PivateModules);
    }
    if (!state.user?.permissoes.includes('*')) {
      const modulos = PivateModules.filter((item) =>
        state.user?.permissoes.some((modulo) => modulo.includes(item.permissao))
      );
      setModulosPrivados(modulos);
    }
  }, [state.user?.permissoes]);
  return (
    <>
      <Box sx={{ textAlign: 'right' }}>
        <IconButton
          size="large"
          onClick={sair}
          sx={{
            color: '#5D5959',
          }}
        >
          <ExitToAppIcon />
        </IconButton>
      </Box>
      <Container
        sx={{
          my: 2,
          borderRadius: '15px',
          textAlign: 'center',

          display: {
            md: 'block',
            xs: 'none',
          },
        }}
      >
        <img src={LogoMinerva} width="35%" alt="" />
      </Container>

      <Container
        maxWidth="xl"
        sx={{
          my: 1,
          borderRadius: '15px',
          textAlign: 'center',
          display: {
            md: 'none',
            xs: 'block',
          },
        }}
      >
        <img src={LogoMinerva} width="80%" alt="" />
      </Container>
      <Container maxWidth="xl" sx={{ mb: 10 }}>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          {modulosPrivados.map((item, index) => (
            <Grid key={index} item md={3} xs={12}>
              <CustomButton data={item} />
            </Grid>
          ))}
          {PublicsModules.map((item, index) => (
            <Grid key={index} item md={3} xs={12}>
              <CustomButton data={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Box
        sx={{
          textAlign: 'center',
          p: 2,
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: '#f0f0f0',
          zIndex: 1000,
          color: '#8c8c8c',
        }}
      >
        <Typography variant="subtitle2">SECRETARIA DE JUSTIÇA E SEGURANÇA PÚBLICA</Typography>
        <Typography variant="subtitle2">Desenvolvido por DMTIC</Typography>
      </Box>
    </>
  );
}
