import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { LinearProgress } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  background: 'white',
  borderRadius: '10px',
  border: 'none',
  p: 4,
};

export default function ModalCarregamento({ isOpen }: { isOpen: boolean }) {
  const [open, setOpen] = useState(isOpen);

  return (
    <div>
      <Modal open={open}>
        <Box sx={style}>
          <Typography variant="h6" sx={{ m: 2 }}>
            Carregando os dados. Aguarde..
          </Typography>

          <LinearProgress color="success" />
        </Box>
      </Modal>
    </div>
  );
}
