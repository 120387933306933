import { Button, Grid, MenuItem } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { getInstituicoes } from 'src/services/get';
import SaveIcon from '@mui/icons-material/Save';

export function FormularioUnidade() {
  const { data: rowsInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: getInstituicoes,
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="instituicaoId" label="Instituição">
          {rowsInstituicoes?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <TextFieldRG name="nome" label="Unidade" />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG name="sigla" label="Sigla" />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
