import DashboardIcon from '@mui/icons-material/Dashboard';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import PeopleIcon from '@mui/icons-material/People';
import AddHomeIcon from '@mui/icons-material/AddHome';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { PriceChange } from '@mui/icons-material';

export interface Module {
  title: string;
  link: string;
  icon: JSX.Element;
  permissao?: string;
}

export const administrador: Module[] = [
  {
    title: 'Usuários',
    link: '/administrador/usuarios',
    icon: <PeopleIcon />,
  },
  {
    title: 'Unidades',
    link: '/administrador/unidades',
    icon: <AddHomeIcon />,
  },
  {
    title: 'Tipo de Despesa',
    link: '/administrador/despesas',
    icon: <PriceChange />,
  },
  {
    title: 'Permissões',
    link: '/administrador/permissoes',
    icon: <LockPersonIcon />,
  },
  //   {
  //     title: 'Módulos',
  //     link: 'modulos',
  //     icon: <PeopleIcon />,
  //   },
];
export const planejamento: Module[] = [
  {
    title: 'Dashboard',
    link: '/planejamento/dashboard',
    icon: <DashboardIcon />,
    permissao: 'PLANEJAMENTO',
  },
  //   {
  //     title: 'Notificações',
  //     link: '/planejamento/notificacao',
  //     icon: <NotificationsActiveIcon />,
  //     permissao: 'MODULE_NOTIFICACAO_PLANEJAMENTO',
  //   },
  {
    title: 'Fundo Nacional',
    link: '/planejamento/fundonacional',
    icon: <SnippetFolderIcon />,
    permissao: 'FUNDO_NACIONAL',
  },
  //   {
  //     title: 'Convênio',
  //     link: '/planejamento/convenio',
  //     icon: <SubjectIcon />,
  //     permissao: 'CONVENIO',
  //   },
];
