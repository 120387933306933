import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Alert, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from '@tanstack/react-query';
import { getUsuario } from 'src/services/get';
import DrawIcon from '@mui/icons-material/Draw';
import { ToastFun } from 'src/config/functions';

import { FormularioAtualizarUsuario } from './formularioAtualizar';

export default function AtualizarUsuario({
  id,
  open,
  handleClose,
}: {
  id: string;
  open: boolean;
  handleClose: any;
}) {
  // PEGAR DADOS DO USUÁRIO
  const { data: user, refetch } = useQuery({
    queryKey: ['getUsuario', id],
    queryFn: getUsuario,
  });

  //  REFECTH SEMPRE QUE FECHAR O MODAL
  useEffect(() => {
    refetch();
  }, [open, refetch]);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            background: 'white',
            borderRadius: '15px',
            p: 5,
            width: {
              xl: '58%',
              md: '75%',
              sm: '70%',
            },
            maxHeight: '650px',
            overflow: 'auto',
            scrollbarWidth: 'none',
            margin: 'auto',
            mt: 3,
          }}
        >
          <Grid container>
            <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
              <IconButton aria-label="delete" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid md={12} xs={12}>
              <Alert
                sx={{ background: '#4B96DD', color: 'white', fontWeight: '700' }}
                icon={<DrawIcon sx={{ color: 'white' }} />}
              >
                Usuário - Editar
              </Alert>
              <Box p={2}>
                {user && (
                  <FormularioAtualizarUsuario
                    dadosUsuario={user}
                    refetch={refetch}
                    handleClose={handleClose}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
