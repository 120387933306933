import TextField from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { useMask } from '@react-input/mask';

interface TextFieldData {
  label: string;
  name: string;
  mask?: string;
  InputProps?: any;
}
export default function TextFieldRGMaks({ label, name, mask, InputProps }: TextFieldData) {
  const inputRef = useMask({ mask: `${mask}`, replacement: { _: /\d/ } });

  const { register } = useFormContext();

  return (
    <TextField
      sx={{ my: 2 }}
      label={label}
      variant="outlined"
      {...register(name)}
      fullWidth
      inputRef={inputRef}
      InputProps={InputProps}
    />
  );
}
