import { useMemo } from 'react';
// @mui
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider, ThemeOptions } from '@mui/material/styles';
// system
import { customShadows } from 'src/theme/custom-shadows';
import { palette } from 'src/theme/palette';
import { componentsOverrides } from 'src/theme/overrides';
import { shadows } from 'src/theme/shadows';
import { typography } from 'src/theme/typography';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const baseOption = useMemo(
    () => ({
      palette: palette('light'),
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      shape: { borderRadius: 8 },
      typography,
    }),

    []
  );

  const theme = createTheme(baseOption as ThemeOptions);

  theme.components = componentsOverrides(theme) as any;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
