import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Chip,
  Modal,
  Alert,
  styled,
  Typography,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import {
  diferencaEmDias,
  tratandoData,
  tratandoValor,
  tratandoValorCentavos,
} from 'src/config/utils';
import { getMetaFisica, getMetaGeral, getMetasGerais, getRemanejamento } from 'src/services/get';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AuthContext } from 'src/Context/ContextUser';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/Admin/Usuarios/typeUsuario';
import { AutorizarRemanejamento } from 'src/services/patch';
import ModalCarregamento from 'src/components/Modal/Carregamento';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function RemanejamentoVisualizar({
  open,
  RefecthRemanejamentos,
  handleClose,
  id,
}: {
  open: boolean;
  handleClose: any;
  id: any;
  RefecthRemanejamentos: any;
}) {
  const { state } = useContext(AuthContext);

  const dadosAutorizacao = {
    id,
    usuarioAuthorizationId: state.user?.usuario.id,
  };
  console.log(dadosAutorizacao);
  const { mutate } = useMutation({
    mutationFn: AutorizarRemanejamento,
    onSuccess: (data) => {
      RefecthRemanejamentos();
      handleClose();
      ToastFun('Plano Autorizado com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const { data: rows, isPending } = useQuery({
    queryKey: ['getRemanejamento', id],
    queryFn: getRemanejamento,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  return (
    <div>
      {rows && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              p: 5,
              width: {
                xl: '70%',
                md: '85%',
                sm: '70%',
              },
              maxHeight: '650px',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            <Grid container justifyContent="right" spacing={1}>
              <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid md={12} xs={12}>
                <Alert
                  sx={{ background: '#FFB146', color: 'white', fontWeight: '700' }}
                  icon={<DrawIcon sx={{ color: 'white' }} />}
                >
                  Remanejamento - Autorização
                </Alert>

                <TableContainer>
                  <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow>
                        <TableCellHead>Execício Orçamentário</TableCellHead>
                        <TableCellHead colSpan={4}>Eixo Financiado</TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCellBody>
                          {rows.metaFisicaRecebida.planoAplicacao.exercicioOrcamentario}
                        </TableCellBody>
                        <TableCellBody colSpan={4}>
                          {rows.metaFisicaRecebida.eixoFinanciado.eixo.nome}
                        </TableCellBody>
                      </TableRow>
                      <TableRow sx={{ background: '#f5f5f5' }}>
                        <TableCellHead>Valor Remanejado</TableCellHead>
                        <TableCellHead>Natureza</TableCellHead>
                        <TableCellHead>Criado Em </TableCellHead>
                        <TableCellHead>Criado Por </TableCellHead>
                      </TableRow>
                      <TableRow>
                        <TableCellBody>
                          R$ {tratandoValorCentavos(rows.valorRemanejado)}
                        </TableCellBody>
                        <TableCellBody>{rows.natureza}</TableCellBody>
                        <TableCellBody>{tratandoData(rows.criadoEm)}</TableCellBody>
                        <TableCellBody>{rows.usuarioCreate.nome}</TableCellBody>
                      </TableRow>
                      <TableRow sx={{ background: '#f5f5f5' }}>
                        <TableCellHead>Meta Física - Retirada</TableCellHead>
                        <TableCellHead>Meta Física - Recebida</TableCellHead>
                        <TableCellHead>Instituição - Retirada</TableCellHead>
                        <TableCellHead>Instituição - Recebida</TableCellHead>
                      </TableRow>
                      <TableRow>
                        <TableCellBody>{rows.metaFisicaRetirada.nome}</TableCellBody>
                        <TableCellBody>{rows.metaFisicaRecebida.nome}</TableCellBody>
                        <TableCellBody>{rows.instituicaoRetirada.instituicao.sigla}</TableCellBody>
                        <TableCellBody>{rows.instituicaoRecebida.instituicao.sigla}</TableCellBody>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item lg={1.5} md={2}>
                <Button
                  fullWidth
                  onClick={handleClose}
                  variant="contained"
                  color="info"
                  startIcon={<SubdirectoryArrowLeftIcon />}
                >
                  Voltar
                </Button>
              </Grid>
              {dadosAutorizacao.id && (
                <Grid item lg={1.5} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    startIcon={<TaskAltIcon />}
                    onClick={() => mutate(dadosAutorizacao)}
                  >
                    Autorizar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  );
}
