import { useState } from 'react';
import { Box, Grid, IconButton, Chip, Modal, Alert, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import { diferencaEmDias, getColor, tratandoData, tratandoValorCentavos } from 'src/config/utils';
import { getPlanoAplicacaoCompleto, getPlanoAplicacaoEixosFinanciados } from 'src/services/get';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
}));

export default function PlanoAplicacaoVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: any;
}) {
  const { data: rows, isPending } = useQuery({
    queryKey: ['getPlanoAplicacao', id],
    queryFn: getPlanoAplicacaoCompleto,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  console.log(rows);
  return (
    <div>
      {!isPending && rows && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              p: 5,
              width: {
                xl: '70%',
                md: '85%',
                sm: '70%',
              },
              maxHeight: '650px',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            <Grid container>
              <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid md={12} xs={12}>
                <Alert
                  sx={{ background: '#4B96DD', color: 'white', fontWeight: '700' }}
                  icon={<DrawIcon sx={{ color: 'white' }} />}
                >
                  Plano de Ação - {rows.exercicioOrcamentario}
                </Alert>
                <TableContainer>
                  <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow>
                        <TableCellHead colSpan={2}>Exercício Orçamentário</TableCellHead>
                        <TableCellHead align="center">Prazo Para Execução</TableCellHead>
                        <TableCellHead align="center">Data Final do Prazo</TableCellHead>
                        <TableCellHead align="center">Situação</TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCellBody colSpan={2}>{rows.exercicioOrcamentario}</TableCellBody>

                        <TableCellBody align="center">
                          <Chip
                            sx={{ color: 'white' }}
                            label={`${diferencaEmDias(rows.prazoExecucao)} Dias`}
                            color={getColor(diferencaEmDias(rows.prazoExecucao))}
                          />
                        </TableCellBody>
                        <TableCellBody align="center">
                          {tratandoData(rows.prazoExecucao)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          <Chip
                            label={rows.status}
                            sx={{ color: 'white' }}
                            color={rows.status === 'Pendente' ? 'warning' : 'success'}
                          />
                          {}
                        </TableCellBody>
                      </TableRow>
                      <TableRow sx={{ background: '#f5f5f5' }}>
                        <TableCellHead>Eixos</TableCellHead>
                        <TableCellHead align="center">Valor de Investimento</TableCellHead>
                        <TableCellHead align="center">Valor de Custeio</TableCellHead>

                        <TableCellHead align="center">Conta Investimento</TableCellHead>
                        <TableCellHead align="center">Conta Custeio</TableCellHead>
                      </TableRow>
                      {rows.eixoFinanciado.map((item: any, index: number) => (
                        <TableRow key={index}>
                          <TableCellBody>{item.eixo.nome}</TableCellBody>
                          <TableCellBody align="center">
                            <>R$ {tratandoValorCentavos(item.valorInicialInvestimento)} </>
                          </TableCellBody>
                          <TableCellBody align="center">
                            <>R$ {tratandoValorCentavos(item.valorInicialCusteio)} </>
                          </TableCellBody>
                          <TableCellBody align="center">{item.contaInvestimento}</TableCellBody>

                          <TableCellBody align="center">{item.contaCusteio}</TableCellBody>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {rows.status === 'Autorizado' && (
                <Box my={2}>
                  <Alert severity="info">
                    Autorizado por : {rows.planoAplicacaoUsuarioAuthorization.nome}
                  </Alert>
                </Box>
              )}
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  );
}
