import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Container,
  Stack,
  Typography,
  Divider,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
} from '@mui/material';
import DrawIcon from '@mui/icons-material/Draw';

interface Dados {
  children: JSX.Element;
  title: string;
  backgroundAlert?: string;
}

export function CustomContainerCad({ children, title, backgroundAlert }: Dados) {
  return (
    <Box
      sx={{
        border: '1px solid #FBFBFB',
        borderRadius: '10px',
        background: 'white',
        p: 2,
        boxShadow: '1px 1px 5px 1px #c4c4c4',
      }}
    >
      <Alert
        sx={{ mt: -5, background: '#019362', color: 'white', fontWeight: '700' }}
        icon={<DrawIcon sx={{ color: 'white' }} />}
      >
        {title} - Cadastro{' '}
      </Alert>
      <Box sx={{ p: 2, my: 2 }}>{children}</Box>
    </Box>
  );
}

export function CustomContainerAtualizar({ children, title, backgroundAlert }: Dados) {
  return (
    <Box
      sx={{
        border: '1px solid #FBFBFB',
        borderRadius: '10px',
        background: 'white',
        p: 2,
        boxShadow: '1px 1px 5px 1px #c4c4c4',
      }}
    >
      <Alert
        sx={{ mt: -5, background: '#4B96DD', color: 'white', fontWeight: '700' }}
        icon={<DrawIcon sx={{ color: 'white' }} />}
      >
        {title} - Editar
      </Alert>
      <Box sx={{ p: 2, my: 2 }}>{children}</Box>
    </Box>
  );
}
export function CustomContainerList({ children, title, backgroundAlert }: Dados) {
  return (
    <Box
      sx={{
        border: '1px solid #FBFBFB',
        borderRadius: '10px',
        background: 'white',
        p: 2,
        my: 2,
        boxShadow: '1px 1px 5px 1px #c4c4c4',
      }}
    >
      <Alert
        sx={{
          mt: -5,
          background: `${backgroundAlert || '#FFB146'}`,
          color: 'white',
          fontWeight: '700',
        }}
        icon={<DrawIcon sx={{ color: 'white' }} />}
      >
        {title}
      </Alert>
      <Box sx={{ p: 2, my: 2 }}>{children}</Box>
    </Box>
  );
}
