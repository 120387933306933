const Endpoints = {
  auth: '/auth/login',
  eixos: '/eixo',
  usuario: '/usuario',
  unidade: '/unidade',
  instituicao: '/instituicao',
  eixoFinanciado: '/eixo-financiado',
  metaGeral: '/meta-geral',
  metaFisica: '/meta-fisica',
  metaFisicaValor: 'meta-fisica/metas-fisicas-valor',
  metaFisicaRemanejamento: 'meta-fisica/metas-fisicas-remanejamento',
  usuarioPermissao: '/usuario/permissao',
  planoAplicacao: '/plano-aplicacao',
  tipoDespesa: '/tipo-despesa',
  remanejamento: '/remanejamento',
  planoAplicacaoEixos: '/plano-aplicacao/eixos',
  planoAplicacaoExercicioEixos: '/plano-aplicacao/exercicio-eixo',
  planoAplicacaoEixosFinanciados: '/plano-aplicacao/eixos-financiados',
};

export default Endpoints;
