import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { tratandoData, tratandoValorCentavos } from 'src/config/utils';
import { getRemanejamentos } from 'src/services/get';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import ModalExcluir from 'src/components/Modal/Excluir';
import { ErroResponse } from 'src/pages/Admin/Usuarios/typeUsuario';
import { ToastFun } from 'src/config/functions';
import { ExcluirRemanejamento } from 'src/services/delete';
import CadastrarRemanejamento from './Cadastrar';
import RemanejamentoVisualizar from './Visualizar';

export default function Remanejamentos() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const {
    data: rows,
    refetch: RefecthRemanejamentos,
    isPending,
  } = useQuery({
    queryKey: ['getRemanejamento', skip, take, search],
    queryFn: getRemanejamentos,
  });
  const { mutate } = useMutation({
    mutationFn: ExcluirRemanejamento,
    onSuccess: (data: any) => {
      ToastFun('Excluido com Sucesso', 'success');
      RefecthRemanejamentos();
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'natureza',
      headerName: 'NATUREZA',
      flex: 1,
      headerAlign: 'center',
      minWidth: 150,
      align: 'center',
    },
    // {
    //   field: 'metaFisicaRetirada',
    //   headerName: 'META FÍSICA - RETIRADA',
    //   flex:1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: (params) => <>{params.row.metaFisicaRetirada.nome}</>,
    // },
    {
      field: 'instituicaoRetirada',
      headerName: 'INSTITUIÇÃO - RETIRADA',
      flex: 1,
      headerAlign: 'center',
      minWidth: 250,
      align: 'center',
      renderCell: (params) => <>{params.row.instituicaoRetirada.instituicao.sigla}</>,
    },
    // {
    //   field: 'metaFisicaRecebida',
    //   headerName: 'META FÍSICA - RECEBIDA',
    //   flex:1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: (params) => <>{params.row.metaFisicaRecebida.nome}</>,
    // },
    {
      field: 'instituicaoRecebida',
      headerName: 'INSTITUIÇÃO - RECEBIDA',
      flex: 1,
      headerAlign: 'center',
      minWidth: 250,
      align: 'center',
      renderCell: (params) => <>{params.row.instituicaoRecebida.instituicao.sigla}</>,
    },
    {
      field: 'valorRemanejado',
      headerName: 'VALOR REMANEJADO',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valorRemanejado)}</>,
    },

    {
      field: 'criadoEm',
      headerName: 'CRIADO EM ',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visualizar')}
          >
            <VisibilityIcon />
          </IconButton>
          {params.row.status === 'Pendente' && (
            <IconButton
              sx={{ color: 'gray' }}
              onClick={() => handleButtonClick(params.row.id, 'Excluir')}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Remanejamento', href: '/planejamento/fundonacional/Remanejamento' },
          ]}
        />
        <Grid container spacing={2}>
          <Grid item xs={6} md={9} sx={{ my: 2 }}>
            <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }} onClick={inputSearch}>
              <SearchIcon />
            </IconButton>
            <Grow
              in={checked}
              style={{ transformOrigin: '10 0 0' }}
              {...(checked ? { timeout: 1000 } : {})}
            >
              <TextField size="small" onChange={(event) => setSearch(event.target.value)} />
            </Grow>
          </Grid>
          <Grid item xs={12} md={3} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('', 'Cadastro')}
              startIcon={<AddIcon />}
            >
              Remanejamento
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Remanejamento">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
        {id && isModalOpen && modalType === 'Excluir' && (
          <ModalExcluir
            isOpen={isModalOpen}
            nomeAcao="Remanejamento"
            id={id}
            mutate={mutate}
            handleClose={handleCloseModal}
          />
        )}
        {id && isModalOpen && modalType === 'Visualizar' && (
          <RemanejamentoVisualizar open={isModalOpen} id={id} handleClose={handleCloseModal} />
        )}
        {isModalOpen && modalType === 'Cadastro' && (
          <CadastrarRemanejamento
            open={isModalOpen}
            handleClose={handleCloseModal}
            RefecthRemanejamentos={RefecthRemanejamentos}
          />
        )}
      </Stack>
    </Box>
  );
}
