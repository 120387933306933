export function formatarValor(valorEmCentavos: number) {
  const valorEmReais = valorEmCentavos / 100;

  return valorEmReais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function formatarDataBrasileira(data: string) {
  const date = new Date(data);

  return date.toLocaleDateString('pt-BR');
}
export function formatarCPF(cpf: string) {
  cpf = cpf.replace(/\D/g, '');

  // Adiciona a pontuação
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  return cpf;
}
