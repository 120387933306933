import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface ItemListProps {
  title: string;
  Icon: JSX.Element;
  select: boolean;
  link: string;
  open: any;
}

const ItemList: React.FC<ItemListProps> = ({ title, Icon, select, link, open }) => (
  <ListItem key={title} disablePadding sx={{ display: 'block', my: 2, px: open ? 2 : 0.3 }}>
    <ListItemButton
      className="listMenu"
      href={link}
      selected={select}
      sx={{
        justifyContent: 'center',
        borderRadius: 1,

        '&.MuiListItemButton-root': {
          color: '#626262',
        },
        '&.MuiListItemButton-root.Mui-selected 	': {
          background: '#019362',
          color: 'white',
        },
        '&.MuiListItemButton-root.Mui-selected .MuiListItemIcon-root 	': {
          color: 'white',
        },
        '&.MuiListItemButton-root.Mui-selected .MuiTypography-root 	': {
          fontWeight: 700,
        },
        // botão em destaque
        '&.MuiListItemButton-root:hover ': {
          background: '#016e49',
          color: 'white',
        },
        '&.MuiListItemButton-root:hover .MuiListItemIcon-root': {
          color: 'white',
        },
      }}
    >
      <ListItemIcon
        sx={{
          justifyContent: 'center',
          color: '#626262',
        }}
      >
        {Icon}
      </ListItemIcon>
      <ListItemText
        primary={title}
        sx={{
          fontSize: '10px',
          '&.MuiListItemText-root .MuiTypography-root ': {
            fontWeight: 700,
          },
        }}
      />
    </ListItemButton>
  </ListItem>
);

export default ItemList;
