import axios from 'axios';
import { URL_API } from 'src/config/constants';

export const Api = axios.create({ baseURL: URL_API });
const user = localStorage.getItem('user');

if (user) {
  Api.interceptors.request.use((request) => {
    const userData = JSON.parse(user);

    if (userData.token) {
      request.headers.Authorization = `Bearer ${userData.token}`;
    }
    return request;
  });
}
