import React, { Children } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

interface FormData {
  children: JSX.Element;
  methods: any;
  handleSubmit: any;
}
export default function FormsProvider({ children, methods, handleSubmit }: FormData) {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>{children}</form>
    </FormProvider>
  );
}
