import { Api } from './Api';
import Endpoints from './endpoinst';

// Admin
interface DadosUsuario {
  id: string;
  permissao: {
    nome: string;
  }[];
}

export async function InserirPermissaoUsuario(dadosUsuario: DadosUsuario) {
  const response = await Api.patch(`${Endpoints.usuarioPermissao}/${dadosUsuario.id}`, {
    permissao: dadosUsuario.permissao,
  });
  return response.data;
}

export async function AtualizarUsuario(dadosUsuario: any) {
  const response = await Api.patch(`${Endpoints.usuario}/${dadosUsuario.id}`, {
    dadosUsuario,
  });
  return response.data;
}

export async function AtualizarPlanoEixos(dadosPlano: any) {
  const response = await Api.patch(`${Endpoints.planoAplicacao}/${dadosPlano.planoAplicacao.id}`, {
    dadosPlano,
  });
  return response.data;
}
export async function AutorizarPlano(dadosAutorizacao: any) {
  const response = await Api.patch(`${Endpoints.planoAplicacao}/autorizar/${dadosAutorizacao.id}`, {
    dadosAutorizacao,
  });
  return response.data;
}
export async function AutorizarMeta(dadosAutorizacao: any) {
  const response = await Api.patch(`${Endpoints.metaGeral}/autorizar/${dadosAutorizacao.id}`, {
    dadosAutorizacao,
  });
  return response.data;
}
export async function AutorizarRemanejamento(dadosAutorizacao: any) {
  const response = await Api.patch(`${Endpoints.remanejamento}/autorizar/${dadosAutorizacao.id}`, {
    dadosAutorizacao,
  });
  return response.data;
}
export async function AtualizarMetaGeralID(dadosPlano: any) {
  const MetaGeral = dadosPlano.MetaGeral;
  const response = await Api.patch(`${Endpoints.metaGeral}/${MetaGeral.id}`, { MetaGeral });
  return response.data;
}
