import { styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridColDef,
  useGridApiRef,
  gridClasses,
  GridAutosizeOptions,
  GridOverlay,
  GridRowParams,
} from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

interface DataTable {
  columns: any;
  rows: any;
  rowCount: any;
  paginationModel: any;
  onPaginationModelChange: any;
}
export default function TableContainer({
  columns,
  rows,
  rowCount,
  paginationModel,
  onPaginationModelChange,
}: DataTable) {
  const apiRef = useGridApiRef();
  function getRowClassName(params: GridRowParams) {
    return 'custom-row';
  }
  const autosizeOptions: GridAutosizeOptions = {
    includeOutliers: true,
  };
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',

        '& .MuiDataGrid-columnHeaderTitle': {
          fontSize: '11px',
          fontWeight: 600,
          color: '#555555',
        },
        '& .MuiDataGrid-cell': {
          color: '#555555',

          textTransform: 'uppercase',
        },
      }}
    >
      <DataGrid
        style={{ border: 'none' }}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        rowCount={rowCount}
        rowHeight={25}
        getRowHeight={() => 'auto'}
        autosizeOptions={autosizeOptions}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 0.5,
            display: 'flex',
            alignItems: 'center',
          },
          '& .custom-row': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      />
    </Box>
  );
}
