import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { SomaTotal, tratandoData, tratandoValorCentavos } from 'src/config/utils';
import { getPlanoAplicacao } from 'src/services/get';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SearchIcon from '@mui/icons-material/Search';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import PlanoAplicacaoVisualizar from './Visualizar';
import AtualizarPlano from './Atualizar';

export default function PlanoAplicacao() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const {
    data: rows,
    refetch: RefecthPlano,
    isPending,
  } = useQuery({
    queryKey: ['getPlanoAplicacao', skip, take, search],
    queryFn: getPlanoAplicacao,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'EXERCÍCIO ORÇAMENTÁRIO',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: '_count',
      headerName: 'EIXOS FINANCIADOS',
      flex: 1,
      headerAlign: 'center',
      minWidth: 150,
      align: 'center',
      renderCell: (params) => <>{params.row._count.eixoFinanciado}</>,
    },

    {
      field: 'eixoFinanciado',
      headerName: 'VALOR CUSTEIO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => {
        const ValorCusteio: number = SomaTotal(params.row.eixoFinanciado, 'valorInicialCusteio');
        return <>R$ {tratandoValorCentavos(ValorCusteio)}</>;
      },
    },
    {
      field: 'valorInicialInvestimento',
      headerName: 'VALOR INVESTIMENTO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => {
        const ValorInvestimento: number = SomaTotal(
          params.row.eixoFinanciado,
          'valorInicialInvestimento'
        );
        return <>R$ {tratandoValorCentavos(ValorInvestimento)}</>;
      },
    },
    {
      field: 'valorTotal',
      headerName: 'VALOR TOTAL',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => {
        const ValorTotal: number = SomaTotal(
          params.row.eixoFinanciado,
          'valorInicialInvestimento',
          'valorInicialCusteio'
        );
        return <>R$ {tratandoValorCentavos(ValorTotal)}</>;
      },
    },

    {
      field: 'prazoExecucao',
      headerName: 'PRAZO DE EXECUÇÃO',
      flex: 1,
      headerAlign: 'center',
      minWidth: 150,

      align: 'center',
      renderCell: (params) => tratandoData(params.row.prazoExecucao),
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,

      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visuzalizar')}
          >
            <VisibilityIcon />
          </IconButton>

          {params.row.status === 'Pendente' && (
            <IconButton color="warning" onClick={() => handleButtonClick(params.row.id, 'Editar')}>
              <ModeEditIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];
  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Plano de Aplicação', href: '/planejamento/fundonacional/planodeaplicacao' },
          ]}
        />
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} sx={{ my: 2 }}>
            <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }} onClick={inputSearch}>
              <SearchIcon />
            </IconButton>
            <Grow
              in={checked}
              style={{ transformOrigin: '10 0 0' }}
              {...(checked ? { timeout: 1000 } : {})}
            >
              <TextField size="small" onChange={(event) => setSearch(event.target.value)} />
            </Grow>
          </Grid>

          <Grid item xs={6} md={6} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              href="/planejamento/fundonacional/planodeaplicacao/cadastro"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Novo Plano
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Plano de Aplicação ">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
      </Stack>

      {id && isModalOpen && modalType === 'Visuzalizar' && (
        <PlanoAplicacaoVisualizar open={isModalOpen} handleClose={handleCloseModal} id={id} />
      )}
      {id && isModalOpen && modalType === 'Editar' && (
        <AtualizarPlano
          open={isModalOpen}
          handleClose={handleCloseModal}
          id={id}
          RefecthPlano={RefecthPlano}
        />
      )}
    </Box>
  );
}
