import { Api } from './Api';
import Endpoints from './endpoinst';

// Admin
interface DadosUsuario {
  id: string;
  permissao: {
    nome: string;
  }[];
}

export async function ExcluirPermissaoUsuario(dadosUsuario: DadosUsuario) {
  const response = await Api.delete(`${Endpoints.usuario}/${dadosUsuario.id}`, {
    data: {
      permissao: dadosUsuario.permissao,
    },
  });
  return response.data;
}
export async function ExcluirEixoFinanciado(dadosEixo: any) {
  const response = await Api.delete(`${Endpoints.eixoFinanciado}/${dadosEixo.id}`, {
    data: {
      status: dadosEixo.status,
    },
  });
  return response.data;
}
export async function ExcluirMetaGeral(dados: any) {
  const response = await Api.delete(`${Endpoints.metaGeral}/${dados}`);
  return response.data;
}
export async function ExcluirMetaFisica(dados: any) {
  const response = await Api.delete(`${Endpoints.metaFisica}/${dados}`);
  return response.data;
}
export async function ExcluirRemanejamento(dados: any) {
  const response = await Api.delete(`${Endpoints.remanejamento}/${dados}`);
  return response.data;
}
