import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { tratandoData } from 'src/config/utils';
import { getUsuarios } from 'src/services/get';
import { formatarCPF } from 'src/utils/functions';
import AtualizarUsuario from './Atualizar';

export default function Usuarios() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;
  const [open, setOpen] = useState(false);

  // BUSCAR DADOS DO USUARIO
  const [idUser, setIdUser] = useState<string>('');

  const handleOpen = (id: any) => {
    setIdUser(id);
    setOpen(true);
  };

  const { data: rows, refetch } = useQuery({
    queryKey: ['getUsuarios', skip, take],
    queryFn: getUsuarios,
  });

  const handleClose = () => {
    setOpen(false);
    refetch();
  };

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      flex: 1,
      renderCell: (params) => formatarCPF(params.row.cpf),
    },

    {
      field: 'criadoEm',
      headerName: 'Data de Criação',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'status',
      headerName: 'Situação',
      flex: 1,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => (
        <Chip
          label={params.row.status}
          color={params.row.status === 'Ativo' ? 'success' : 'error'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 50,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'right' }}>
          <IconButton color="warning" onClick={() => handleOpen(params.row.id)}>
            <ModeEditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Usuários"
          links={[
            { name: 'Administrador', href: '/administrador' },
            { name: 'Usuários', href: '/administrador/usuarios' },
          ]}
        />
        <Grid container spacing={2}>
          <Grid xs={12} md={12} sx={{ textAlign: 'right' }}>
            <Button
              href="usuarios/cadastro"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ mt: -5 }}
            >
              Novo Usuário
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Usuários">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>

        <AtualizarUsuario id={idUser} open={open} handleClose={handleClose} />
      </Stack>
    </Box>
  );
}
