import { Api } from './Api';
import Endpoints from './endpoinst';

// ADMIN
export async function getUsuarios({ queryKey }: { queryKey: any }) {
  const [_key, skip, take] = queryKey;
  const response = await Api.get(Endpoints.usuario, {
    params: {
      skip,
      take,
    },
  });
  return response.data;
}

export async function getUsuario({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.usuario}/${id}`);
  return response.data;
}

// FUNDO NACIONAL

// PLANO
export async function getPlanoAplicacao({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search] = queryKey;
  const filters = { search };
  const response = await Api.get(Endpoints.planoAplicacao, {
    params: {
      skip,
      take,
      filters,
    },
  });
  return response.data;
}

export async function getPlanoAplicacaoEixosFinanciados({ queryKey }: { queryKey: any }) {
  const [_key, id, skip, take] = queryKey;
  const response = await Api.get(`${Endpoints.planoAplicacaoEixos}/${id}`, {
    params: {
      skip,
      take,
    },
  });
  return response.data;
}

export async function getPlanoAplicacaoCompleto({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.planoAplicacao}/completo/${id}`);
  return response.data;
}

// PEGA OS EIXOS CADASTRADOS EM TODOS OS PLANOS
export async function getExericioOrcamentario() {
  const response = await Api.get(Endpoints.planoAplicacaoExercicioEixos);
  return response.data;
}

// EIXO

export async function getEixos({ queryKey }: { queryKey: any }) {
  const [_key, skip, take] = queryKey;
  const response = await Api.get(Endpoints.eixos, {
    params: {
      skip,
      take,
    },
  });
  return response.data;
}

export async function getEixosFinanciados({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.planoAplicacaoEixosFinanciados}/${id}`, {});
  return response.data;
}

// METAS
export async function getMetasGerais({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search] = queryKey;
  const filters = { search };
  const response = await Api.get(Endpoints.metaGeral, {
    params: {
      skip,
      take,
      filters,
    },
  });
  return response.data;
}

export async function getMetaGeral({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.metaGeral}/${id}`);

  return response.data;
}

export async function getMetaFisica({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.metaFisica}/${id}`);

  return response.data;
}

export async function getRemanejamento({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.remanejamento}/${id}`);

  return response.data;
}

export async function getMetasFisicas({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search, planoAplicacaoId, eixoFinanciadoId] = queryKey;
  const filters = { search };
  const response = await Api.get(Endpoints.metaFisica, {
    params: {
      skip,
      take,
      filters,
      planoAplicacaoId,
      eixoFinanciadoId,
    },
  });
  return response.data;
}

export async function getMetasFisicasValor({ queryKey }: { queryKey: any }) {
  const [_key, planoAplicacaoId, eixoFinanciadoId] = queryKey;
  const response = await Api.get(Endpoints.metaFisicaValor, {
    params: {
      planoAplicacaoId,
      eixoFinanciadoId,
    },
  });
  return response.data;
}

export async function getMetasFisicasRemanejamento({ queryKey }: { queryKey: any }) {
  const [_key, idExercicio, idEixoFinancido, natureza] = queryKey;
  const response = await Api.get(Endpoints.metaFisicaRemanejamento, {
    params: {
      plano: idExercicio,
      eixoFinanciado: idEixoFinancido,
      natureza,
    },
  });
  return response.data;
}

// INSTITUIÇÃO
export async function getInstituicoes() {
  const response = await Api.get(Endpoints.instituicao);
  return response.data;
}

export async function getRemanejamentos({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search] = queryKey;
  const filters = { search };

  const response = await Api.get(Endpoints.remanejamento, {
    params: {
      skip,
      take,
      filters,
    },
  });
  return response.data;
}

export async function getUnidadesInstituicao({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.unidade}/${id}`);

  return response.data;
}

export async function getUnidades() {
  const response = await Api.get(`${Endpoints.unidade}`);

  return response.data;
}

// DESPESAS
export async function getTipoDespesas({ queryKey }: { queryKey: any }) {
  const [_key, skip, take] = queryKey;
  const response = await Api.get(Endpoints.tipoDespesa, { params: { skip, take } });
  return response.data;
}
