import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { RoutePrivate, RouteProtect } from 'src/Auth/RouteProtect';
import MenuCards from 'src/pages/CardMenu';
import Login from 'src/pages/Login';
import LayoutSidebar from 'src/components/Layout';
import FundoNacionalMenu from 'src/pages/Planejamento/FundoNacional';
import PlanoAplicacao from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao';
import CadastrarPlano from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Cadastro';
import CadastrarMetasFisicas from 'src/pages/Planejamento/FundoNacional/Metas/Cadastro/MetaFisica';
import MetasPlano from 'src/pages/Planejamento/FundoNacional/Metas';
import { PageError } from 'src/pages/PageErro';
import Usuarios from 'src/pages/Admin/Usuarios';
import CadastroUsuario from 'src/pages/Admin/Usuarios/Cadastro';
import { Container, Typography } from '@mui/material';
import Dashboard from 'src/pages/Planejamento/Dashboard';
import Autorizacao from 'src/pages/Planejamento/FundoNacional/Autorizacao';
import PlanoAplicacaoVisualizarAutorizacao from 'src/pages/Planejamento/FundoNacional/Autorizacao/Visualizar/PlanoAplicacao';
import Remanejamento from 'src/pages/Planejamento/FundoNacional/Remanejamento';
import MetaGeralVisualizarAutorizacao from 'src/pages/Planejamento/FundoNacional/Autorizacao/Visualizar/MetaGeral';
import Unidades from 'src/pages/Admin/Unidades';
import Permissoes from 'src/pages/Admin/Permissoes';
import { Despesas } from '../pages/Admin/Despesas';

const router = createBrowserRouter([
  {
    path: '*',
    element: <PageError />,
  },
  {
    element: <Login />,
    path: '/',
  },
  {
    element: <RouteProtect element={<MenuCards />} />,
    path: '/menu',
  },
  {
    element: <RoutePrivate element={<LayoutSidebar />} permission="ADMIN" />,
    path: '/administrador',
    children: [
      // ADMINISTRADOR
      {
        element: (
          <Container sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Selecione um Módulo</Typography>
          </Container>
        ),
        path: '',
      },
      {
        element: <Usuarios />,
        path: 'usuarios',
      },
      {
        element: <CadastroUsuario />,
        path: 'usuarios/cadastro',
      },
      {
        element: <Unidades />,
        path: 'unidades',
      },
      {
        element: <Despesas />,
        path: 'despesas',
      },
      {
        element: <Permissoes />,
        path: 'permissoes',
      },

      // PLANEJAMENTO
    ],
  },
  {
    element: <RoutePrivate element={<LayoutSidebar />} permission="PLANEJAMENTO" />,
    path: '/planejamento',
    children: [
      // PLANEJAMENTO
      {
        element: <Navigate to="dashboard" />,
        path: '',
      },
      {
        element: <RoutePrivate element={<Dashboard />} permission="PLANEJAMENTO" />,
        path: 'dashboard',
      },
      {
        element: <RoutePrivate element={<FundoNacionalMenu />} permission="FUNDO_NACIONAL" />,
        path: 'fundonacional',
      },
      {
        element: (
          <RoutePrivate
            element={<PlanoAplicacao />}
            permission="PLANEJAMENTO_FUNDO_NACIONAL_LISTAR"
          />
        ),
        path: 'fundonacional/planodeaplicacao',
      },
      {
        element: (
          <RoutePrivate
            element={<CadastrarPlano />}
            permission="PLANEJAMENTO_FUNDO_NACIONAL_CRIAR"
          />
        ),
        path: 'fundonacional/planodeaplicacao/cadastro',
      },
      {
        element: (
          <RoutePrivate element={<MetasPlano />} permission="PLANEJAMENTO_FUNDO_NACIONAL_LISTAR" />
        ),
        path: 'fundonacional/metas',
      },
      {
        element: <RoutePrivate element={<Autorizacao />} permission="PLANEJAMENTO_*" />,
        path: 'fundonacional/autorizacao',
      },
      {
        element: (
          <RoutePrivate
            element={<PlanoAplicacaoVisualizarAutorizacao />}
            permission="PLANEJAMENTO_*"
          />
        ),
        path: 'fundonacional/autorizacao/plano/:id',
      },
      {
        element: (
          <RoutePrivate element={<MetaGeralVisualizarAutorizacao />} permission="PLANEJAMENTO_*" />
        ),
        path: 'fundonacional/autorizacao/meta/:id',
      },
      {
        element: (
          <RoutePrivate
            element={<Remanejamento />}
            permission="PLANEJAMENTO_FUNDO_NACIONAL_REMANEJAR"
          />
        ),
        path: 'fundonacional/remanejamento',
      },
      {
        element: (
          <RoutePrivate
            element={<CadastrarMetasFisicas />}
            permission="PLANEJAMENTO_FUNDO_NACIONAL_CRIAR"
          />
        ),
        path: 'fundonacional/metas/cadastro',
      },
    ],
  },
]);

export default router;
