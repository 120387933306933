import { Box, Modal, Grid, IconButton, Alert, MenuItem, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import { getEixosFinanciados, getExericioOrcamentario } from 'src/services/get';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/Admin/Usuarios/typeUsuario';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import { postCadastrarMetaGeral } from 'src/services/post';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { schemaMetaGeral } from './type';

export default function CadastroMetaGeral({
  open,
  handleClose,
  RefecthMetas,
}: {
  open: boolean;
  handleClose: any;
  RefecthMetas: any;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaMetaGeral),
  });

  const {
    watch,
    formState: { errors },
  } = methods;
  const { state } = useContext(AuthContext);

  const { data: exercicioOrcamentario } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: getExericioOrcamentario,
  });

  const idExercicio = watch('MetaGeral.planoAplicacaoId');

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', idExercicio],
    queryFn: getEixosFinanciados,
    enabled: !!idExercicio,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: postCadastrarMetaGeral,
    onSuccess: (data) => {
      ToastFun('Meta Cadastrada com sucesso', 'success');
      handleClose();
      RefecthMetas();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const onSubmit = async (data: any) => {
    data.MetaGeral.usuarioCreateId = state.user?.usuario.id;
    mutate(data);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Metas Gerais - Cadastro
            </Alert>
            <FormsProvider methods={methods} handleSubmit={onSubmit}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={4}>
                  <TextFieldRG
                    select
                    name="MetaGeral.planoAplicacaoId"
                    label="Exercício Orçamentário"
                  >
                    {exercicioOrcamentario &&
                      exercicioOrcamentario.results.map((item: any, indexExercicio: number) => (
                        <MenuItem key={indexExercicio} value={item.id}>
                          {item.exercicioOrcamentario}
                        </MenuItem>
                      ))}
                  </TextFieldRG>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextFieldRG select name="MetaGeral.eixoFinanciadoId" label="Eixo/Área Temática">
                    {eixosFinanciados?.results[0].eixoFinanciado.map(
                      (item: any, indexEixoFinanciado: number) => (
                        <MenuItem key={indexEixoFinanciado} value={item.id}>
                          {item.eixo.nome}
                        </MenuItem>
                      )
                    )}
                  </TextFieldRG>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextFieldRG type="text" name="MetaGeral.nome" label="Meta geral" />
                </Grid>

                <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="success"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
            </FormsProvider>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
