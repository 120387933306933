import { Alert, Box, Grid, IconButton, Modal, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import { tratandoValorCentavos } from 'src/config/utils';
import { getMetaFisica } from 'src/services/get';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaFisicaVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: any;
}) {
  const { data: rowsMeta, isPending } = useQuery({
    queryKey: ['getMetaFisica', id],
    queryFn: getMetaFisica,
  });

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  return (
    <div>
      {rowsMeta && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              p: 5,
              width: {
                xl: '70%',
                md: '85%',
                sm: '70%',
              },
              maxHeight: '650px',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            <Grid container>
              <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid md={12} xs={12}>
                <Alert
                  sx={{ background: '#4B96DD', color: 'white', fontWeight: '700' }}
                  icon={<DrawIcon sx={{ color: 'white' }} />}
                >
                  Meta Física
                </Alert>

                <TableContainer>
                  {/* DADOS DA META - ANO - EIXO -META GERAL */}
                  <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow>
                        <TableCellHead>Exercicío Orçamentário</TableCellHead>
                        <TableCellHead>Eixo Financiado</TableCellHead>
                        <TableCellHead colSpan={3}>Meta Geral</TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCellBody>
                          {rowsMeta[0].planoAplicacao.exercicioOrcamentario}
                        </TableCellBody>
                        <TableCellBody>{rowsMeta[0].eixoFinanciado.eixo.nome}</TableCellBody>
                        <TableCellBody colSpan={3}>{rowsMeta[0].metaGeral.nome}</TableCellBody>
                      </TableRow>
                      <TableRow sx={{ background: '#f5f5f5' }}>
                        <TableCellHead colSpan={2}>Meta Física</TableCellHead>
                        <TableCellHead>Natureza</TableCellHead>
                        <TableCellHead colSpan={2}>Tipo de Despesa</TableCellHead>
                      </TableRow>
                      <TableRow>
                        <TableCellBody colSpan={2}>{rowsMeta[0].nome}</TableCellBody>
                        <TableCellBody>{rowsMeta[0].natureza}</TableCellBody>
                        <TableCellBody colSpan={2}>{rowsMeta[0].TipoDespesa.nome}</TableCellBody>
                      </TableRow>
                      <TableRow sx={{ background: '#f5f5f5' }}>
                        <TableCellHead colSpan={2}>INSTITUIÇÃO BENEFICIADA</TableCellHead>
                        <TableCellHead>VALOR INICIAL</TableCellHead>
                        <TableCellHead>UNIDADE</TableCellHead>
                        <TableCellHead>STATUS</TableCellHead>
                      </TableRow>
                      {rowsMeta[0].metasInstituicoes.map((item: any, index: number) => (
                        <TableRow key={index}>
                          <TableCellBody colSpan={2}>{item.instituicao.nome}</TableCellBody>
                          <TableCellBody>
                            R$ {tratandoValorCentavos(item.valorInicial)}
                          </TableCellBody>
                          <TableCellBody>
                            {item.unidade ? item.unidade.nome : 'Unidade Não Atribuida'}
                          </TableCellBody>
                          <TableCellBody>{item.status}</TableCellBody>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  {/* DADOS DA META FISICA -INSTITUIÇÕES */}
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  );
}
