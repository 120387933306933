import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';

export default function Dashboard() {
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Dashboard"
          links={[{ name: 'Planejamento', href: '/planejamento' }]}
        />
        <Box>
          <iframe
            title="dplagePost"
            width="100%"
            height="800px"
            style={{ border: 'none', boxShadow: '1px 1px 10px 1px #c4c4c4', borderRadius: '10px' }}
            src="https://app.powerbi.com/view?r=eyJrIjoiZTJjOTIxNmItZmFkMS00ZWJjLThiNjAtNjY5ODcwMjVhMTZlIiwidCI6IjdmZDRjZTRmLWRjN2UtNDZhMS04ODlhLTg4OWI1ZWFhYjVmZiJ9"
          />
        </Box>
      </Stack>
    </Box>
  );
}
