import { tratandoValor } from 'src/config/utils';
import * as yup from 'yup';

export const schemaMetas = yup.object<MetasGeral>({
  metaGeral: yup.object().shape({
    id: yup.string().required('Meta Geral é Obrigatório'),
    planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
    eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
    usuarioCreateId: yup.string(),
    usuarioUpdateId: yup.string(),
  }),

  metasFisicas: yup
    .array()
    .of(
      yup.object().shape({
        metaGeralId: yup.string(),
        nome: yup.string().required('Meta Física é Obrigatório'),
        natureza: yup.string().required('Natureza é Obrigatório'),
        tipoDespesa: yup.string().required('Despesa é Obrigatório'),
        metasInstituicoes: yup.array().of(
          yup.object().shape({
            metaFisica: yup.string(),
            id: yup.string().required('Intituição é Obrigatório'),
            valorInicial: yup
              .mixed()
              .transform((value: any) => tratandoValor(value))
              .required('Valor é Obrigatório'),
            unidade: yup.string(),
            saldoReal: yup.mixed().transform((value: any) => tratandoValor(value)),
            saldoEstimativo: yup.mixed().transform((value: any) => tratandoValor(value)),
          })
        ),
      })
    )
    .required(),
});

export interface MetasFisica {
  metafisica: string;
  naturezaDespesa: string;
  tipoDespesa: string;
  instituicoesBeneficiadas: [
    {
      instituicaoBeneficiada: string;
      valor: any;
      unidade: string;
    }
  ];
}
export interface MetasGeral {
  metafisica: string;
  naturezaDespesa: string;
  tipoDespesa: string;
  metasFisicas: MetasFisica[];
}
